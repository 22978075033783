{
  "POWR": {
    "SUPPLIES": "Suministros",
    "SUPPLY_POINTS": "Puntos de suministro",
    "SECONDARY_HUB": "Informe de consumos",
    "CLIENTS": "Clientes",
    "CONTRACT": "Contratos",
    "INVOICES": "Facturas",
    "AGREEMENTS": "Convenios",
    "FEES": "Honorarios",
    "PRODUCTS": "Productos",
    "PRICE_RATES": "Tarifas de precios",
    "NO_NOTIFICATIONS": "No hay notificationes",
    "ERROR": "Error",
    "TODO": "Por hacer",
    "TO_CANCEL": "Por cancelar",
    "TO_UNDO": "Por deshacer",
    "UNDOING": "Deshaciendo",
    "DOING": "Ejecutando",
    "CANCELED": "Cancelada",
    "FINISHED": "Finalizada",
    "DISCARDED": "Deshecha",
    "NO_DATA": "No hay datos",
    "Load SIPS": "Cargar SIPS",
    "Previous": "Anterior",
    "Next": "Siguiente",
    "Create": "Crear",
    "Cancel": "Cancelar",
    "ENTITY": "Entidad",
    "OFFICE": "Oficina",
    "DC": "DC",
    "ACCOUNT" : "Cuenta",
    "WRONG_account-number": "Número de cuenta incorrecto",
    "CREATE_CONTRACT": "Crear contrato",
    "INVOICES_SERIES": "Series de facturas",
    "PROVIDERS": "Proveedores",
    "BANKS": "Bancos",
    "TAXES": "Impuestos",
    "LOGOUT": "Salir",
    "COMPANY_DATA": "Datos de empresa",
    "YOUR_ACCOUNT": "Tu cuenta",
    "SETTINGS": "Configuración",
    "PORTFOLIO": "Cartera",
    "REMITTANCES": "Remesas",
    "RECEIPTS": "Recibos",
    "SELECT_PAGE": "Seleccionar Página",
    "SELECT_ALL": "Seleccionar Todo",
    "UNSELECT_PAGE": "Deseleccionar Página",
    "UNSELECT_ALL": "Deseleccionar Todo",
    "REPORTS": "Informes",
    "UPDATE": "Actualizar",
    "COMMERCIAL": "Comercial",
    "CONTACTS": "Contactos",
    "COMPARATIVE_REPORTS": "Informes comparativos",
    "COMMERCIAL_PRICE_RATES": "Precios de ofertas",
    "SIPS": "SIPS",
    "VIEW_SELECTED": "Ver seleccionado",
    "VIEW_ALL": "Ver todo",
    "CALENDARS": "Calendarios",
    "ISSUES": "Eventos",
    "COMPLAINTS": "Reclamaciones",
    "PROGRAMMING_UNITS": "Unidades de Programación",
    "STATE_REQUIRED": "La provincia es obligatoria",
    "DEBTS": "Morosidad",
    "ATR_COMPLAINTS": "Reclamaciones en ATR",
    "ENDORSEMENTS": "Endosos",
    "CLEAN_FILTERS": "Limpiar filtros",
    "SHARE": "Compartir",
    "COPIED_TO_CLIPBOARD": "Copiado al portapapeles",
    "ID": "ID",
    "STATUS": "Estado del servicio"
  },
  "BRANDS": {
  	"TITLE": "Marcas",
  	"ANY": "Cualquiera",
  	"NONE": "Ninguna"
  },
  "USER": {
    "CURRENT_PASS": "Actual password",
    "NEW_PASS": "Nueva password",
    "REPEAT_NEW_PASS": "Repetir nueva password",
    "CHANGE_PASS": "Cambiar password",
    "PASS_UPDATED": "Password de usuario actializada",
    "ERROR": "Error desconocido"
  },
  "SAVE": "Guardar",
  "CANCEL": "Cancelar",
  "CREATE": "Crear",
  "QUEUES": {
    "default": "Cola por defecto",
    "invoicing": "Cola de procesamiento de facturas",
    "files": "Cola de archivos",
    "invoiced": "Cola de facturación",
    "reports": "Cola de informes",
    "EMPTY": "No hay tareas",
    "RUNNING": "Ejecutando",
    "NEXT": "En espera",
    "NOT_WORKING": "¡La cola está parada!",
    "BY": "por",
    "AT": "a las",
    "SYSTEM_TASK": "Tarea programada del sistema"
  }
}