{
  "CONTRACTS": {
    "SUPPLY_CUPS": "CUPS",
    "TITLE": "Contracts",
    "STATUS": "Status",
    "ANY": "Any",
    "AGREEMENT": "Agreement",
    "STATE": "State",
    "PENDING": "Pending",
    "ACTIVE": "Active",
    "TO_ACTIVE": "To active",
    "INACTIVE": "Inactive",
    "ALL": "All",
    "CONTRACT_NUM": "No. contract",
    "CONTRACT": "Contract",
    "HOLDER_DATA": "Holder data",
    "CONTRACT_HOLDER": "Contract holder",
    "BILLING": "Billing",
    "BANK_ACCOUNT_HOLDER": "Bank account holder",
    "BANK_NAME": "Bank name",
    "BANK_account-number": "Bank account number",
    "CONTRACT_DATA": "Contract data",
    "CNAE": "CNAE",
    "EMAIL": "E-mail",
    "ESTIMATED_ANNUAL_CONSUMPTION": "Est. annual consumption",
    "PAYMENT_DATA": "Payment data",
    "ADDRESS": "Address",
    "CITY": "Town",
    "ZIP_CODE": "Zip code",
    "LOADING": "Loading...",
    "DRAFT": "Draft",
    "STATUS_MANDATORY": "Status is required",
    "NAME_MANDATORY": "Name is required",
    "CNAE_MANDATORY": "CNAE is required",
    "ADDRESS_MANDATORY": "Address is required",
    "CITY_MANDATORY": "City is required",
    "STATE_MANDATORY": "State is required",
    "ZIP_MANDATORY": "Zip code is required",
    "BANK_ACCOUNT_MANDATORY": "Bank account is required",
    "BANK_ACCOUNT_HOLDER_MANDATORY": "Bank account holder is required",
    "BANK_ACCOUNT_INVALID": "Bank account is invalid",
    "SEARCH": "Search...",
    "The following error has occured": "The following error has occured",
    "Contract data": "Contract data",
    "Supply data": "Supply data",
    "Customer data": "Customer",
    "Billing address": "Billing address",
    "Incorrect email": "Incorrect email",
    "Agreement": "Agreement",
    "No agreement": "No agreement",
    "Owner data": "Postal data",
    "Contract successfully updated": "Contract successfully updated",
    "Purchased energy": "Purchased energy",
    "Rate": "Rate",
    "Price": "Price",
    "Regularity": "Regularity",
    "Power is required": "Purchased power is required and cannot be zero",
    "Regularity is required": "Regularity is required",
    "Consumptions": "Consumptions",
    "Rate is required": "Rate is required",
    "Default Price": "Choose one",
    "Select rate": "Select rate",
    "Incorrect number": "Incorrect number",
    "Start date": "Start date",
    "Finish date": "Finish date",
    "Contract owner": "Postal owner",
    "Update": "Update",
    "Cancel": "Cancel",
    "Customer update advise": "If you modify customer data, this update apply to every contract that this customer has.",
    "Customer update correctly": "Customer update correctly",
    "Vat id": "DNI/CIF",
    "Incorrect vat id": "Incorrect Vat ID",
    "Required vat id": "Required Vat ID",
    "Name and surname": "Name and surname",
    "Phone": "Phone",
    "Mobile": "Mobile",
    "Fax": "Fax",
    "Postal address": "Postal address",
    "Update data": "Update Datos",
    "Enable supply edition": "Enable supply edition",
    "Enable customer edition": "Enable customer edition",
    "Supply update advise": "If you modify supply data, this update apply to every contract that supply has.",
    "Supply update correctly": "Supply update correctly",
    "Invalid CUPS": "Invalid CUPS",
    "CUPS required": "CUPS required",
    "Distributor": "Distributor",
    "Commercial": "Commercial",
    "Distributor is required": "Distributor is required",
    "Supply Power": "Supply Power",
    "Supply power required": "Supply power required",
    "Supply owner and address": "Supply owner and address",
    "Supply owner": "Supply owner",
    "Registry": "Registry",
    "CHANGE_STATUS": "Change status",
    "DOWNLOAD": "Download",
    "DOWNLOAD_EXCEL": "Download Excel",
    "DOWNLOAD_PDF": "Download PDF",
    "TO_SENT": "To sent",
    "TO_SIGNED": "to signed",
    "TO_DRAFT": "To draft",
    "TO_APP": "To application",
    "TO_NOT_PROCESSED": "To not process",
    "SENT": "Sent",
    "SIGNED": "Signed",
    "APPLICATION": "Application",
    "NOT_PROCESSED": "To not process",
    "CHANGE_QUESTION": "Change?",
    "YES": "Yes",
    "NO": "No",
    "DELETE": "Delete",
    "DELETEQ": "Delete",
    "ELEMENTSQ": "elements?",
    "ACTIONS": "Actions",
    "CHANGE_MANAGER": "Change manager",
    "CHANGE_MANAGER_OF_CONTRACTS": "Change manager of contracts: ",
    "You must enter a number": "You must enter a number",
    "Draft type": "Draft type",
    "Select draft type": "Select draft type",
    "No Agreement": "No Agreement",
    "Select a valid option": "Select a valid option.",
    "Draft type is required": "EDraft type is required.",
    "Select regularity": "Select regularity",
    "Name and Second name or Social Name": "Name or Social Name",
    "RANGE_ERROR_20A": "Power 2.0A must be lower or equal than 10kW.",
    "RANGE_ERROR_20DHA": "Power 2.0DHA must be lower or equal than 10kW.",
    "RANGE_ERROR_21A": "Power 2.1A must be higher than 10kW and lower or equal than 15kW.",
    "RANGE_ERROR_21DHA": "Power 2.1DHA must be higher than 10kW and lower or equal than 15kW.",
    "RANGE_ERROR_30A": "Power 3.0A must be higher than 15kW.",
    "RANGE_ERROR_31A": "Power 3.1A must be lower than 450 KW.",
    "RANGE_ERROR_31DHA": "Power 3.1DHA must be lower than 450 KW.",
    "RANGE_ERROR_61": "Power 6.1 must be higher than 450 KW.",
    "RANGE_ERROR_62": "Power 6.2 must be higher than 450 KW.",
    "RANGE_ERROR_63": "Power 6.3 must be higher than 450 KW.",
    "RANGE_ERROR_64": "Power 6.4 must be higher than 450 KW.",
    "RANGE_ERROR_65": "Power 6.5 must be higher than 450 KW.",
    "Power is not normal": "The given power is not normal",
    "COPY_DATA": "Copy data from",
    "SUPPLY": "Supply",
    "SKIP": "Skip",
    "Supply advise": "This update apply to every contract that supply has.",
    "Customer advise": "This update apply to every contract that this customer has.",
    "Contract successfully created": "Contract successfully created.",
    "Contract error creating": "Error during creation contract. Please try it again.",
    "SAVE": "Save",
    "Manager data": "Manager data",
    "Enable manager edition": "Enable manager edition",
    "Manager update advise": "If you modify manager data, this update apply to every contract that this manager has.",
    "Manager update correctly": "Manager updated correctly",
    "DOWNLOAD_PDF_DEFAULT": "Default PDF",
    "DOWNLOAD_PDF_SIGNED": "Signed PDF",
    "Bulk download generation Task success": "Bulk download generation Task success",
    "Bulk download generation Task error": "Bulk download generation Task error",
    "LOGS": "Logs",
    "SYSTEM": "System",
    "CHANGE_ESTIMATED_CONSUMPTION": "Change estimated annual consumption",
    "BACK": "Back",
    "ESTIMATED_CONSUMPTION_UPDATED": "Estimated annual consumption successfully updated.",
    "ESTIMATED_CONSUMPTION_UPDATED_ERROR": "Error updating estimated annual consumption.",
    "This contract number already exists": "This contract number already exists",
    "DUPLICATE_CONTRACT": "Duplicate contract",
    "DUPLICATE": "Duplicate",
    "Contract successfully duplicated": "Contract successfully duplicated.",
    "Contract error duplicating": "Error during duplicating contract. Please try it again.",
    "SIGN_DATE": "Signature date",
    "SENT_DATE": "Sent date",
    "APP_DATE": "Application date",
    "PRODUCT_RATE": "Product rate",
    "Product rate not in force": "Product rate not in force",
    "Filter by": "Filter by",
    "Date from": "Date from",
    "Date to": "Date to",
    "Sign date range": "Sign date range",
    "Sent date range": "Sent date range",
    "App date range": "Application date range",
    "Expiration date range": "Expiration date range",
    "Activation date range": "Activation date range",
    "Without Filter": "Without Filter",
    "Date range": "Date range",
    "CUSTOMER": "Customer",
    "MANAGER": "Manager",
    "CHANGE_RATE": "Change rate",
    "NEW_CONTRACT": "New contract",
    "CLOSE_PAGE": "There are unsaved changes in contract creation.",
    "ALL_SELECTED": "All",
    "FEES": "Fees",
    "ATTACHED_FILES": "Files",
    "COMMISSION": "Commission",
    "WITHOLDING": "Witholding",
    "LIST_FEES": "List Fees",
    "FROM": "From",
    "TO": "To",
    "DATE": "Date",
    "INVOICE": "Invoice",
    "TAX": "Tax",
    "TOTAL": "Total",
    "AGREEMENT MANAGES CONTRACT": "Agreement manages contract",
    "CHANGE_AGREEMENT": "Change Agreement",
    "DATE_CHANGE_AGREEMENT": "Date change agreement",
    "SET_TO_INVOICE_CONTRACTS_SUCCESS": "Automatic invoice contracts Task success",
    "SET_TO_INVOICE_CONTRACTS_ERROR": "Automatic invoice contracts Task error",
    "AUTO_CONTRACT_INVOICE": "Automatic invoice contracts",
    "OBSERVATIONS": "Observations",
    "VIEW": "View",
    "TO_PRINT": "Must be printed",
    "CHANGE_DATES": "Change contract activation dates",
    "DATE_CONTRACT_FOR_SUPPLY_UPDATED_SUCCESS": "Updated activation contract dates.",
    "DATE_CONTRACT_FOR_SUPPLY_UPDATED_ERROR": "Error when update activation contract_dates",
    "ACTIVATION_DATE_FROM": "FROM",
    "ACTIVATION_DATE_TO": "TO",
    "INVOICES": "Invoices",
    "INVOICES_LIST": "Invoices List for contract:",
    "XML_FROM": "From",
    "XML_TO": "To",
    "XML_DATE": "Importing date",
    "CUSTOMER_CREATED": "Customer created successfully.",
    "START_DATE": "Start date",
    "EXPIRATION_DATE": "Expiration date",
    "DELETED": "Deleted ",
    "UPDATED": "Updated ",
    "CONTRACT_AGREEMENTS": " contract agreements",
    "ERROR_REQUEST": "Error in request",
    "CONTRACT_RATES": "  contract rates",
    "CONTRACT_STATUS": "  contract status.  ",
    "CONTRACT_STATUS_ERROR_NUMBER": "  not updated contracts because customer has not phone number.",
    "ERROR_PERMISSIONS": "You don't have perms to do this action in contracts, or not is possible change to selected status.",
    "CHANGE_PRODUCT_RATE": "Changed product rate",
    "CONTRACT_PRODUCT_RATES": "  contract product rates",
    "ITEM_TO_BE_INVOICED": "Items to be Invoiced",
    "ITEM_TO_BE_INVOICED_LIST": "Items to be Invoiced for contract:",
    "CREATE_POSTPONEMENT": "Defer invoices",
    "CREATE_POSTPONEMENT_TOTAL": "Defer invoices for an amount of : ",
    "SHOW_DEFERRED_INVOICES": "Show deferred invoices",
    "SHOW_ALL_INVOICES": "Show all invoices",
    "RECEIPTS_AMOUNT": "Receipts amount",
    "CREATE_RECEIPTS": "Create receipts",
    "REASON": "Reason",
    "RESET": "Reset",
    "CREATE_DEFERMENT": "Create deferment",
    "DEFERMENT_CREATED_OK": "Deferment successfully created",
    "invoice data uploaded": "Contract invoice data saved successfully",
    "INVOICE_DATA": "Invoice data and electronic invoice",
    "INVOICE_OBSERVATIONS": "Invoice observations",
    "administrative data uploaded": "Einvoice data saved successfully",
    "INDEXED": "Indexed",
    "NORMAL": "Normal",
    "CONTRACT_TYPE": "Invoicing type",
    "SHOW_RETURNED_INVOICES": "Ever been returned",
    "regularity_default_choice": "-- Select the regularity --",
    "PRODUCT_RATES": "Product rates",
    "ACTIVATION_DATE": "Activation date",
    "NEW_PRODUCT_RATE": "New product rate",
    "NEW_PRODUCT_SERVICE": "New product service",
    "PRODUCT_SERVICE_ADDED": "Product service added",
    "ADD": "Add",
    "CANCEL": "Cancel",
    "PRODUCT_RATE_ADDED": "Product rate added",
    "ERROR_ADDING_PRODUCT_RATE": "Error adding product rate",
    "ERROR_PRODUCT_SERVICE_ADDED": "Error adding product service",
    "PRODUCT_RATE_DELETED": "Product rate deleted",
    "ERROR_DELETING_PRODUCT_RATE": "Error deleting product rate",
    "CONFIRM_DELETE": "Do you want to delete this contracts product rate?",
    "UPDATE": "Update",
    "CONFIRM_UPDATE": "Do you want to update?",
    "PRODUCT_RATE_UPDATED": "Product rate updated",
    "PRODUCT_SERVICE_UPDATED": "Product service updated",
    "ERROR_UPDATING_PRODUCT_RATE": "Error updating product rate",
    "CONTRACT_FULLNAME": "Name",
    "CONTRACT_SURNAME_1": "Surname 1",
    "CONTRACT_SURNAME_2": "Surname 2",
    "ADDRESS_NUMBER": "Number",
    "ADDRESS_EXTENSION": "Extension",
    "IE_TYPE": "Electricity duty",
    "EXTRA_EMAILS": "Contract Extra Emails",
    "WEB_ACCESS_RECEIVE_INVOICES": "Web access and invoice receipt",
    "WEB_ACCESS_ONLY": "Only Web Access",
    "ENABLE_CONTRACT_EDITION": "Enable edition",
    "UPDATE_CONTRACT": "Update contract",
    "CONFIRM_UPDATE_CONTRACT": "Now you must introduce a reason to update the contract.",
    "AGREEMENT_MANDATORY": "Agreement is required",
    "NO_RESULTS": "No results",
    "PTO_SUMINISTRO": "Supply point",
    "AGREEMENTS": "Agreements",
    "AGREEMENT_ADDED": "Agreement added",
    "ERROR_ADDING_AGREEMENT": "Error adding agreement",
    "NEW_AGREEMENT": "New agreement",
    "STARTED_DATE": "Start date",
    "CONFIRM_DELETE_AGREEMENTS": "Do you want to delete this agreement?",
    "CONFIRM_UPDATE_AGREEMENTS": "Do you want to update this agreement?",
    "AGREEMENT_DELETED": "Agreement deleted",
    "AGREEMENT_UPDATED": "Agreement updated",
    "SUPPLY_CREATE_SUCCESS": "Supply created successfully",
    "RATE_PRICE_NOT_MATCH": "Product prices selected is not valid for the rate selected.",
    "INVALID_VALUE": "Invalid value",
    "ZIP_LENGTH": "Must be contain 5 digits only",
    "REJECTED": "Rejected",
    "CHANGE": "Change",
    "INCIDENCE": "Court for non-payment",
    "CREATED": "Created ",
    "DATE_CHANGE_PRODUCT_RATE": "Change date",
    "FINISH_DATE": "Finish date",
    "PROGRAMMING_PRODUCT_RATE_RENOVATION": "Programming renovation",
    "POWER_T20_ERROR": "Power must be lower than or equal to 10kW.",
    "POWER_T21_ERROR": "Power must be higher than 10kW and lower than or equal to 15kW.",
    "POWER_T3X_ERROR": "Power must be higher than 15kW and lower than KW.",
    "POWER_T6X_ERROR": "Power must be higher than or equal to 450 KW.",
    "SEARCHING": "Searching....",
    "EDIT": "Edit",
    "SUPPLY_UPDATE_SUCCESS": "Supply updated successfully",
    "CREATE": "Create",
    "PAYMENT_VAT_ID": "Payment ID",
    "NO_CUPS": "There isn't CUPS",
    "SEPA": "SEPA Reference",
    "SEPA_CODE_REQUIRED": "SEPA reference is required",
    "NUMERIC_FIELD": "Only digits are allowed",
    "SEPA_LENGTH": "The length must be 20",
    "PRINT_INVOICES": "Print invoices",
    "CONFIRM_BULK_UPDATE": "The change will be performed in the selected contracts!",
    "CONTRACTS_UPDATED": "Selected contracts was updated successfully",
    "ONLY_MANAGED": "Only managed contracts",
    "POSTAL_DATA": "Postal data",
    "BULK_UPDATE": "Bulk update",
    "MULTIPOINT_CONTRACT": "Multipoint contract",
    "NAME": "Name",
    "MAIN_EMAIL": "Main email",
    "SECONDARY_EMAIL": "Secondary email",
    "CIF": "CIF",
    "PHONE": "Phone",
    "IBAN": "IBAN",
    "UNIQUE_ACCOUNT": "Unique account number",
    "SEPA_GENERATED_BY_CIF": "SEPA generated by CIF",
    "ZIP": "ZIP",
    "Download multipoint contract success": "Download multipoint contract success",
    "Download multipoint contract error": "Download multipoint contract error",
    "MULTIPOINT_ADVICE": "There cannot be different agreements for selected contracts. For every single rate related to contracts, there can only be one product price",
    "UNSUBSCRIBE_CONTRACT": "Unsubscribe contract request",
    "EVENT_CREATED_FOR_CONTRACT": "Issue created for contract unsubscribe request",
    "Update customer": "Customer Update",
    "CURRENT_CUSTOMER_ADVICE": "The current customer is {{ name }} {{ surname_1 }} {{ surname_2 }}, with vat id {{ vat_id }}.",
    "GOTO": "Go to...",
    "DOWNLOAD_PDF_RENEW_DEFAULT": "Default PDF for renovation",
    "DOWNLOAD_PDF_RENEW_SIGNED": "Signed PDF for renovation",
    "EVENT_MAXIMETRE_CREATED_FOR_CONTRACT": "Issue created for contract maximetre request",
    "MAXIMETRE_CONTRACT": "Maximetre contract request",
    "DOWNLOAD_PDF_DEFAULT_WITHOUT_SUPPLY_INFO": "Default PDF without supply info",
    "DOWNLOAD_PDF_SIGNED_WITHOUT_SUPPLY_INFO": "Signed PDF without supply info",
    "CONTRACT_BANK_DEFAULT_NUMBER": "  not updated contracts because payment account with zero value.",
    "UNSUBSCRIBE_QUESTION": "Unsubscribe request?",
    "SELECT_MOTIVE": "Selcet Motive",
    "INVOICED_MODE_REQUIRED": "Contract invoiced mode is required",
    "SELECT_INVOICE_MODE": "Select invoice mode",
    "RECEIVER_CONTRACT_REFERENCE": "Receiver contract reference",
    "FILE_REFERENCE": "File reference",
    "TO_PENDING": "To data pending",
    "CUSTOMER_DATA": "Customer data",
    "BULK_UPDATE_QUEUED": "The bulk update is queued",
    "BULK_UPDATE_REJECTED": "The bulk update have errors",
    "PENDING_RENEW": "Pending for renew",
    "PENDING_DATE": "Pending date",
    "PENDING_DATE_ATR": "Pending date ATR",
    "This contract number doesn't exists": "This contract number doesn't exists",
    "POINT_TYPE_REQ": "Measuse point type required",
    "INVALID_CITY_VALUE": "Invalid city value",
    "DISCOUNT": "Discount (%)",
    "NOT_TO_UPDATE": "Not Update: ",
    "ADMINISTRATIVE_CHANGES_CONTRACT": "Administrative contract changes request",
    "CUSTOMER_CHANGES_CONTRACT": "Customer contract changes request",
    "INVOICES_PERIODICITY_CHANGES_CONTRACT": "Invoice periodicity contract change request",
    "EVENT_ADMINISTRATIVE_CHANGES_CREATED_FOR_CONTRACT": "Issue created for contract administrative changes",
    "EVENT_CUSTOMER_CHANGES_CREATED_FOR_CONTRACT": "Issue created for contract customer request",
    "EVENT_INVOICE_PERIODICITY_CHANGES_CREATED_FOR_CONTRACT": "Issue created for contract invoice periodicity request",
    "SUB_STATUS": "Situation",
    "TO_READY": "To ready",
    "TO_DATA_NEEDED": "To data needed",
    "TO_DATA_NEEDED_ATR": "To data needed ATR",
    "CLOSE_INVOICE_DATE": "Close invoice period",
    "CLOSE_INVOICE_DATE_OF": "Invoice close date for",
    "ESTIMATED_FINISH_DATE": "Estimated invoice finish date for",
    "CLOSE_INVOICEQ": "Do you really want to close the estimated period of",
    "CLOSE_PERIOD": "Close period",
    "DATA_NEEDED": "Data needed",
    "DATA_NEEDED_ATR": "Data needed ATR",
    "CHANGE_NOTICE": "Change notice",
    "UNPAID": "Unpaid",
    "CHANGE_NUMBER": "Change number",
    "RESIGNATION": "Resignation",
    "CHANGE_COMMERCIAL": "Change commercial",
    "MANAGEMENT_CHANGE": "Management Change",
    "TO_CONNECT": "To connect",
    "CONNECTION_STATUS": "Connection",
    "CLOSE_ESTIMATED_PERIOD": "Close estimated invoicing",
    "Close estimated invoice Task Created": "Close estimated invoice Task Created",
    "Close estimated invoice Task Creation ERROR": "Close estimated invoice Task Creation ERROR",
    "CREATE_ESTIMATED_INVOICE": "Create estimated invoice",
    "GENERATE_INVOICE": "Generate invoice",
    "Contracts will be invoiced with the given close date": "Contracts will be invoiced with the given close date",
    "CHANGE_INVOICING_MODE": "Change invoicing mode",
    "CONTRACT_CAN_NOT_BE_PROCESS": "Contracts can't be process",
    "CONTRACT_PERMS_NOT_PROCESS": "Contracts can't be process, perms error",
    "ATR_COMMENTS": "ATR Instructions",
    "CONTRACT_INVOICE_GENERATION_MODE": "Invoicing generation mode",
    "CHANGE_CONTRACT_INVOICE_GENERATION_MODE": "Change Invoicing generation mode",
    "VALIDITY_DATE": "Validity date",
    "ADD_ITEM_TO_BE_INVOICED": "Add Item",
    "ITEM_TO_BE_INVOICE_ADDED": "Item to be invoice added successfully",
    "CONFIRM_DELETE_ITEM": "Do you want to delete this item?",
    "ITEM_TO_BE_INVOICE_DELETED": "Item has been deleted successfully",
    "ITEM_TO_BE_INVOICE_UPDATED": "Item has been updated successfully",
    "EXCLUDED_PRODUCT": "Excluded Products",
    "PRODUCT_SERVICE": "Product Service",
    "PRODUCT_SERVICES_ASSOCIATED": "Product services associated",
    "DENOMINATION": "Denomination",
    "MAX_EXCLUSION": "Max exclusion",
    "COUNT_EXCLUSION": "Count Exclusion",
    "UPDATED_DATE": "Last mod date",
    "CONFIRM_DELETE_EXCLUDED_PRODUCTS": "Confirm delete excluded products",
    "CONFIRM_UPDATE_EXCLUDED_PRODUCTS": "Confirm update excluded products",
    "EXCLUDED_PRODUCT_UPDATED": "Excluded product updated",
    "EXCLUDED_PRODUCT_DELETED": "Excluded product deleted",
    "EXCLUDED_PRODUCT_ADD": "Excluded product created",
    "NEW_EXCLUDED_PRODUCT": "New excluded product",
    "REMITTANCE_DAY": "Remittance day",
    "DEFAULT_REMITTANCE_DAY": "Default remittance day",
    "CHANGE_REMITTANCE_DAY": "Change contract remittance day",
    "COMPLAINTS_LIST": "Complaints List for contract:",
    "COMPLAINT_NUMBER": "N. Complaint",
    "COMPLAINT_SUBTYPE": "Subtype",
    "COMPLAINT_DATE": "Creation Date",
    "COMPLAINT_STATUS": "Status",
    "INVOICE_COVER": "Invoices cover",
    "M1_EVENT_GENERATION": "Do you want to generate ATR M1 event link to this change rate?",
    "NEW_PRODUCT_TO_INVOICE": "New product to be invoiced",
    "TO_ANOMALY_FRAUD": "To Anomaly / Fraud",
    "ANOMALY_FRAUD": "Anomaly / Fraud",
    "ADD_PRODUCT_NOTE": "For VARS and PENALTIES this text will be shown as invoice line text",
    "QUANTITY": "Quantity",
    "ADD_TO_CONTRACT": " Add to contract",
    "DOWNLOAD_SIGNED_PDF": "Signed Contract",
    "BULK_CONTRACT_DOWNLOAD_CSV": "Bulk download report generation Task success",
    "FIXED_DATE": "Fixed Date",
    "CONTRACT_FIXED_DATE": "N. Contracts to sub_status SENT by fixed date",
    "COMMENT": "Comment",
    "ADD_COMMENT": "Add comment",
    "Bulk_Comment": "Bulk comment generation",
    "WITHDRAWAL_CONTRACT": "Withdrawal contract request",
    "WITHDRAWAL_QUESTION":"Withdrawal request?",
    "WITHDRAWAL_NOT_IN_DATE_QUESTION_1": "Contract activation date is higher than 15 days",
    "EVENT_EVENT_WITHDRAWAL_CREATED_FOR_CONTRACT_CREATED_FOR_CONTRACT": "Issue created for contract withdrawal request",
    "INVALID_PRODUCT_RATE": "Selected product rate has not all prices for the rate assigned",
    "ERROR_CHECKING_PRODUCT_RATE": "Error checking product rate for the contract",
    "SELECT_PLACE": "Select sign place",
    "SELECT_PLACE_TO_DOWNLOAD_PDF": "Select Place to download pdf",
    "CONTRACT_DATE_MANUAL": "Contract date (leave blank if you don't want to use)",
    "FEE_PRICES": "Fee prices",
    "Customer": "Customer",
    "Supply": "Supply",
    "Contract": "Contract",
    "CONTRACT_SELF_CONSUMPTION_TYPE": "Self consumption type",
    "ESTIMATED": "Invoice generated as estimated",
    "PRODUCT_PRICES_ASSOCIATED": "Product Prices Associated",
    "PRODUCT_PRICE_DELETED": "Product price deleted",
    "PRODUCT_PRICE_UPDATED": "Price updated",
    "PRODUCT_PRICE_ADDED": "Price deleted",
    "CONFIRM_DELETE_PRICE": "Do you want to delete this price?",
    "ESTIMATE_INVOICE": "Generate estimate invoice",
    "CONFIRM_INVOICE_GENERATION": "An estimated invoice will be generate for every contract",
    "ESTIMATED_PROFILED": "Invoice generated as estimated profiled",
    "CREATION_DATE": "Creation date",
    "RELATED_CONTRACTS": "Related contracts",
    "NO_RELATED_CONTRACTS": "There are no related contracts",
    "ADD_RELATED_CONTRACT": "Add related contract",
    "CONTRACT_ID": "Contract ID",
    "CONFIRM_DELETE_RELATED": "Are you sure you want to delete this related contract?",
    "CONFIRM_DELETED_RELATED": "Successfully deleted",
    "CONFIRM_UPDATE_RELATED": "Successfully updated",
    "ERROR_CONFIRM_ADD_RELATED": "Unable to add related contract",
    "CONFIRM_ADD_RELATED": "Added successfully",
    "ACTIVITY_CESSATION": "Activity cessation",
    "END_CONTRACT": "End of energy contract",
    "SUPPLY_CUT": "Supply cut",
    "UNPAID_CONTRACT": "Unpaid finish contract",
    "NON_UNPLUG_CONTRACT":  "Deregistration without definitive disconnection of facilities",
    "REGULARIZE": "Regularize",
    "REGULARIZE_INSTRUCTIONS": "Select the date range to be regularised.",
    "REGULARIZE_OK": "Regularization request created. Check your tasks.",
    "REGULARIZE_ERROR": "Error while creating the regularization request.",
    "STRICT_DATES": "Use strict dates",
    "ADJUST_CONTRACTQ": "Do you want to adjust ",
    "ADJUST_CONTRACTS": "Adjust contracts",
    "ADJUST_START_DATE": "Adjustment start date",
    "OPTIONAL": "Optional",
    "DUPLICATE_CONTRACTS": "Duplicate contracts",
    "INITIAL_ID": "Initial ID",
    "Bulk_duplicate_contracts": "Bulk duplicate contracts task created. Check your tasks.",
    "task_creation_error": "Task creation failed. Try again later.",
    "DUPLICATE_SERVICES": "Duplicate services",
    "DUPLICATE_SPECIAL_PRICES": "Duplicate special prices",
    "NEXT_INVOICE": "Next invoice",
    "CANCEL_UNSUBSCRIBE_REQUEST": "Cancel Unsubscribe request",
    "EVENT_CANCEL_UNSUBSCRIBE_FOR_CONTRACT": "Issue created for cancel unsubscribe request",
    "DELETE_SELECTED_ITEMS": "Delete selected items",
    "RELATED_DATA": "Related data",
    "FORCE_CONTRACT_STATUS": "Force contract status",
    "Bulk_force_contract_status": "Force contract status task created",
    "Bulk_force_contract_task_error": "Error creating a Force contract status task",
    "CHANNEL": "Contract Channel",
    "SELECT_CHANNEL": "Select a channel",
    "GENERATE_FLAT_RATE_INVOICES": "Generate Flat Rate Invoices",
    "GENERATE_FLAT_RATE_INVOICESQ": "Generate invoices for ",
    "FLAT_RATE_DATE": "Flat rate date",
    "Generate Flat Rate Invoices Task Created": "Flat rate invoice generation task created successfully",
    "Generate Flat Rate Invoices Task Creation ERROR": "Flat rate invoice generation task creation failed",
    "CHANNEL_REQUIRED": "Channel is required",
    "PRODUCTS_RATES_INFO": "Product Rates Information",
    "PRICE_COVERAGE_CODE": "Price Coverage Code",
    "PRICE_COVERAGE_VALUE": "Price Coverage Value",
    "Flat rate": "Flat rate",
    "Flat rate price": "Flat rate fee (Wihout taxes)",
    "INVOICE_GENERATION_MODE": "Invoice generation mode",
    "ENERGY_PRICE": "Energy Price",
    "POWER_PRICE": "Power Price",
    "RATE_AND_PRODUCT_RATE_PRICES":  "Rate and Product Rate Prices",
    "ATR_REQUEST_TITLE": "ATR",
    "ATR_REQUEST_LIST": "ATR Request List for contract: ",
    "PROVIDER_INVOICE": "Provider Invoice",
    "ORIGIN": "Origin",
    "VALUE": "Value",
    "TYPE": "Type",
    "CONTRACT_OUTGOING_ENERGY_INVOICING_MODE": "Outgoing energy invoicing mode"
  }
}
