const InvoicesAutoCtrl = ['$scope', '$filter', 'Task', 'Alert', 'filtersUtils',
    function ($scope, $filter, Task, Alert, filtersUtils) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');
        var self = this;

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.ordering = {
            "id": $translate('CONTRACTS.CONTRACT_NUM'),
            "status": $translate('CONTRACTS.STATUS')
        };

        $scope.draggableFields = {
            "idn__in":{
                "label": $translate('CONTRACTS.CONTRACT_NUM')
            },
            "cups__in": {
                "label": $translate('CONTRACTS.SUPPLY_CUPS')
            }
        };

        $scope.itemsToInvoice =[];

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
            $scope.counter = selectedCounterChange;
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/
        // List of contracts which can be changed
        function emptyLists() {
            $scope.backgroundTasks = [];
            $scope.block = false;
        }

        this.getJSONParams = function (elements) {
            var params = {};
            params.ids = filtersUtils.getItemsToDownloadToInt(elements);
            return params;
        };

        this.blockElements = function(){
            for (var el in $scope.results){
                if($scope.itemsToInvoice.hasOwnProperty($scope.results[el].id) && $scope.itemsToInvoice[$scope.results[el].id] == true)
                        $scope.results[el].block = true;
                else
                    $scope.results[el].block = false;
            }
        };

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.isReadyToInvoice = function () {
            return $scope.counter > 0;
        };

        $scope.makeInvoices = function () {
            // To block element in UI
            self.blockElements();

            var task = new Task();
            task.type = 'IVGN';
            task.params = self.getJSONParams($scope.itemsToInvoice);
            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('info', $translate('CONTRACTS.SET_TO_INVOICE_CONTRACTS_SUCCESS'), true);
                $scope.backgroundTasks.push(data.data.id);
            }, function (data, status, headers, config) {
                // TODO: better error control
                Alert.createAlert('danger', $translate('CONTRACTS.SET_TO_INVOICE_CONTRACTS_ERROR'), true);
            })

            /* TODO: Cuando borro la lista?
            .finally(function () {
                emptyLists();
            });
            */
        };

        $scope.deleteItemsToBeInvoice = function () {
            self.blockElements();

            var task = new Task();
            task.type = 'IVDL';
            task.params = self.getJSONParams($scope.itemsToInvoice);
            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('info', $translate('CONTRACTS.SET_TO_INVOICE_CONTRACTS_SUCCESS'), true);
                $scope.backgroundTasks.push(data.data.id);
            }, function (data, status, headers, config) {
                Alert.createAlert('danger', $translate('CONTRACTS.SET_TO_INVOICE_CONTRACTS_ERROR'), true);
            })
            
            /*
            .finally(function () {
                emptyLists();
            });
            */
        };

        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        emptyLists();

    }
]

export { InvoicesAutoCtrl };
