
const PaymentDataFormCtrl =  ['$scope', 'CreateContract', 'CitiesMaster', '$filter',
        function ($scope, CreateContract, CitiesMaster, $filter) {
            /***********************************************************************************************************
            * Controller Attributes
            ***********************************************************************************************************/
            var self = this;
            var $translate = $filter('translate');

            /***********************************************************************************************************
            * Scope Attributes
            ***********************************************************************************************************/
            $scope.message = $translate('CONTRACTS.CLOSE_PAGE');

            $scope.payment_methods = [
                {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
                {code: 'DBT', label: $translate('INVOICES.DEBIT')},
                {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')},
                {code: 'GBT', label: $translate('INVOICES.DEBIT_GBT')}
            ];
            /***********************************************************************************************************
            * Scope Watchers
            ***********************************************************************************************************/
            $scope.$watch('contract.payment_name', function (newValue, oldValue) {
                if (newValue != null) {
                    $scope.$emit("setValidity", true);
                    CreateContract.setPaymentData($scope.contract);
                } else {
                    $scope.$emit("setValidity", false);
                    CreateContract.setPaymentData(null);
                }
            }, true);

            $scope.$watch('contract.payment_account', function (newValue, oldValue) {
                if (newValue != null) {
                    $scope.$emit("setValidity", true);
                    CreateContract.setPaymentData($scope.contract);
                }
            }, true);

            /***********************************************************************************************************
            * Controller Methods
            ***********************************************************************************************************/
            this.fillData = function (data, from) {
                if (data) {
                    var name = '';
                    var surname_1 = '';
                    var surname_2 = '';
                    if ($scope.contract == null)
                        $scope.contract = {};
                    if (data.name !== null)
                        name = data.name;
                    if (data.surname_1 !== null)
                        surname_1 = data.surname_1;
                    if (data.surname_2 !== null)
                        surname_2 = data.surname_2;
                    if (data.vat_id !== null)
                        $scope.contract.payment_vat_id = data.vat_id;
                    if (data.payment_method !== null)
                        $scope.contract.payment_method = data.payment_method;
                    if (data.sepa_code !== null)
                        $scope.contract.sepa_code = data.sepa_code;
                    if (data.address !== null)
                        $scope.contract.payment_address = data.address;
                    if (data.city !== null)
                        $scope.contract.payment_city = data.city;
                    if (data.state !== null)
                        $scope.contract.payment_state = data.state;
                    if (data.zip !== null)
                        $scope.contract.payment_zip = data.zip;
                    if (data.remittance_day !== null)
                        $scope.contract.remittance_day = data.remittance_day;

                    $scope.contract.payment_name = name + ' ' + surname_1 + ' ' + surname_2;
                    if (from == 'agreement') {
                        $scope.contract.payment_account = data.default_contract_payment_account;
                        $scope.contract.payment_method = data.default_contract_payment_method;
                        $scope.contract.payment_vat_id = data.tax_id;
                        if (data.remittance_day !== null)
                            $scope.contract.remittance_day = data.remittance_day;
                    }
                    else
                        $scope.contract.payment_account = data.bank_account || data.payment_account || '';
                }
            };

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/
            $scope.pasteData = function (from) {
                var data = null;
                if (from == 'supply') {
                    data = CreateContract.getSupply();
                    self.fillData(data, from);
                } else if (from == 'customer') {
                    data = CreateContract.getCustomer();
                    self.fillData(data, from);
                } else if (from == 'agreement') {
                    data = CreateContract.getAgreement();
                    self.fillData(data, from);
                }
            };

            $scope.skip = function () {
                CreateContract.setPaymentData(null);
                $scope.$emit("setValidity", true);
            };

            /***********************************************************************************************************
            * Controller Initialization
            ***********************************************************************************************************/

            CitiesMaster.loadCities().then(function(response){
                $scope.cities=response;
            });

        }]

export {PaymentDataFormCtrl}
