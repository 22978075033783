{
  "CONTRACTS": {
    "SUPPLY_CUPS": "CUPS",
    "TITLE": "Contratos",
    "STATUS": "Estado",
    "ANY": "Cualquiera",
    "AGREEMENT": "Convenio",
    "STATE": "Provincia",
    "PENDING": "Pendiente",
    "ACTIVE": "Activo",
    "TO_ACTIVE": "A activo",
    "INACTIVE": "Inactivo",
    "ALL": "Todo",
    "CONTRACT_NUM": "Nº Contrato",
    "CONTRACT": "Contrato",
    "HOLDER_DATA": "Datos del titular",
    "CONTRACT_HOLDER": "Titular del contrato",
    "BILLING": "Dirección fiscal",
    "BANK_ACCOUNT_HOLDER": "Titular de cuenta bancaria",
    "BANK_NAME": "Nombre de la entidad bancaria",
    "BANK_account-number": "Número de cuenta bancaria",
    "CONTRACT_DATA": "Datos del contrato",
    "CNAE": "CNAE",
    "EMAIL": "E-mail",
    "ESTIMATED_ANNUAL_CONSUMPTION": "Consumo anual est.",
    "PAYMENT_DATA": "Datos de pago",
    "ADDRESS": "Dirección",
    "CITY": "Población",
    "ZIP_CODE": "Código postal",
    "LOADING": "Cargando...",
    "DRAFT": "Borrador",
    "STATUS_MANDATORY": "El estado es obligatorio",
    "NAME_MANDATORY": "El nombre es obligatorio",
    "CNAE_MANDATORY": "El CNAE es obligatorio",
    "ADDRESS_MANDATORY": "La dirección es obligatoria",
    "CITY_MANDATORY": "La población es obligatoria",
    "STATE_MANDATORY": "La provincia es obligatoria",
    "ZIP_MANDATORY": "El código postal es obligatorio",
    "BANK_ACCOUNT_MANDATORY": "La cuenta bancaria es obligatoria",
    "BANK_ACCOUNT_HOLDER_MANDATORY": "El titular de la cuenta bancaria es obligatorio",
    "BANK_ACCOUNT_INVALID": "La cuenta bancaria es inválida",
    "SEARCH": "Buscar...",
    "The following error has occured": "Se ha producido el siguiente error",
    "Contract data": "Datos del contrato",
    "Supply data": "Punto de suministro",
    "Customer data": "Cliente",
    "Billing address": "Dirección de facturación",
    "Incorrect email": "El e-mail es incorrecto",
    "Agreement": "Convenio",
    "No agreement": "Sin convenio",
    "Owner data": "Datos de correspondencia",
    "Contract successfully updated": "Contrato actualizado correctamente",
    "Purchased energy": "Potencia contratada",
    "Rate": "Tarifa",
    "Price": "Precio",
    "Regularity": "Periodicidad",
    "Power is required": "Las potencias contratadas son obligatorias y no pueden ser cero",
    "Regularity is required": "La periodicidad es obligatoria",
    "Consumptions": "Listado de consumos",
    "Rate is required": "La tarifa es obligatoria",
    "Default Price": "Selecciona uno",
    "Select rate": "Seleccione tarifa",
    "Incorrect number": "El válor numérico no es válido",
    "Start date": "Fecha Inicio",
    "Finish date": "Fecha Fin",
    "Contract owner": "Titular correspondencia",
    "Update": "Actualizar",
    "Cancel": "Cancelar",
    "Customer update advise": "Tenga en cuenta que al modificar los datos del cliente, estos datos se actualizarán en todos los contratos de dicho cliente.",
    "Customer update correctly": "Cliente actualizado correctamente",
    "Vat id": "DNI/CIF",
    "Incorrect vat id": "El DNI/CIF incorrecto",
    "Required vat id": "El DNI/CIF es obligatorio",
    "Name and surname": "Nombre y apellidos",
    "Phone": "Teléfono",
    "Mobile": "Movil",
    "Fax": "Fax",
    "Postal address": "Dirección postal",
    "Update data": "Actualizar Datos",
    "Enable supply edition": "Desbloquear edición Punto de suministro",
    "Enable customer edition": "Desbloquear edición Cliente",
    "Supply update advise": "Tenga en cuenta que al modificar los datos del Punto de Suministro, estos datos se actualizarán para todos los contratos relacionados con dicho punto.",
    "Supply update correctly": "Punto de Suministro actualizado correctamente.",
    "Invalid CUPS": "El CUPS introducido no es válido.",
    "CUPS required": "EL CUPS es obligatorio",
    "Distributor": "Distribuidora",
    "Commercial": "Comercializadora",
    "Distributor is required": "La distribuidora es obligatoria",
    "Supply Power": "Potencia punto de suministro",
    "Supply power required": "Las potencias del punto de suministro son obligatorias y no pueden ser cero.",
    "Supply owner and address": "Titular y dirección de suminsitro",
    "Supply owner": "Titular del suministro",
    "Registry": "Referencia catastral",
    "CHANGE_STATUS": "Cambiar estado",
    "DOWNLOAD": "Descargar",
    "DOWNLOAD_EXCEL": "Descargar en Excel",
    "DOWNLOAD_PDF": "Descargar en PDF",
    "TO_SENT": "A enviado",
    "TO_SIGNED": "A firmado",
    "TO_DRAFT": "A borrador",
    "TO_APP": "A solicitud",
    "TO_NOT_PROCESSED": "A no tramitar",
    "SENT": "Enviado",
    "SIGNED": "Firmado",
    "APPLICATION": "Solicitud",
    "NOT_PROCESSED": "No tramitar",
    "CHANGE_QUESTION": "¿Cambiar?",
    "YES": "Sí",
    "NO": "No",
    "DELETE": "Eliminar",
    "DELETEQ": "¿Eliminar",
    "ELEMENTSQ": "elementos?",
    "ACTIONS": "Acciones",
    "CHANGE_MANAGER": "Cambiar manager",
    "CHANGE_MANAGER_OF_CONTRACTS": "Cambiar manager de los contratos: ",
    "You must enter a number": "Debe introducir un número",
    "Draft type": "Tipo de borrador",
    "Select draft type": "Seleccione tipo de borrador",
    "No Agreement": "Sin convenio",
    "Select a valid option": "Seleccione una opción válida",
    "Draft type is required.": "El tipo de borrador es obligatorio.",
    "Select regularity": "Seleccione periodicidad",
    "Name and Second name or Social Name": "Nombre o Razón Social",
    "RANGE_ERROR_20A": "La tarifa 2.0A debe tener una potencia  inferior o igual a 10kW.",
    "RANGE_ERROR_20DHA": "La tarifa 2.0DHA debe tener una potencia  inferior o igual a 10kW.",
    "RANGE_ERROR_21A": "La tarifa 2.1A debe tener una potencia mayor de 10kW y menor o igual a 15kW.",
    "RANGE_ERROR_21DHA": "La tarifa 2.1DHA debe tener una potencia mayor de 10kW y menor o igual a 15kW.",
    "RANGE_ERROR_30A": "La tarifa 3.0A debe tener una potencia mayor a 15kW.",
    "RANGE_ERROR_31A": "Las tarifas 3.1A deben tener una potencia inferior a 450 KW.",
    "RANGE_ERROR_31DHA": "Las tarifas 3.1DHA deben tener una potencia inferior a 450 KW.",
    "RANGE_ERROR_61": "Las tarifas 6.1 deben tener una potencia superior a 450 KW.",
    "RANGE_ERROR_62": "Las tarifas 6.2 deben tener una potencia superior a 450 KW.",
    "RANGE_ERROR_63": "Las tarifas 6.3 deben tener una potencia superior a 450 KW.",
    "RANGE_ERROR_64": "Las tarifas 6.4 deben tener una potencia superior a 450 KW.",
    "RANGE_ERROR_65": "Las tarifas 6.5 deben tener una potencia superior a 450 KW.",
    "Power is not normal": "La potencia introducida no es una potencia normalizada",
    "COPY_DATA": "Copiar datos desde",
    "SUPPLY": "Pto. de suministro",
    "SKIP": "Omitir",
    "Supply advise": "Estos datos se actualizarán para todos los contratos relacionados con este punto de suministro.",
    "Customer advise": "Estos datos se actualizarán en todos los contratos del cliente.",
    "Contract successfully created": "Contrato creado correctamente.",
    "Contract error creating": "Error creando el contrato. Por favor vuelva a intentarlo.",
    "SAVE": "Guardar",
    "Manager data": "Datos del Manager",
    "Enable manager edition": "Desbloquear edición del Manager",
    "Manager update advise": "Tenga en cuenta que al modificar los datos del Manager, estos datos se actualizarán para todos los contratos relacionados con dicho cliente/manager.",
    "Manager update correctly": "Manager actualizado correctamente.",
    "DOWNLOAD_PDF_DEFAULT": "PDF por defecto",
    "DOWNLOAD_PDF_SIGNED": "PDF con firma",
    "Bulk download generation Task success": "La generación masiva de contratos se ha puesto en cola",
    "Bulk download generation Task error": "Se ha producido un error en la generación de la tarea de descarga",
    "LOGS": "Logs",
    "SYSTEM": "Sistema",
    "CHANGE_ESTIMATED_CONSUMPTION": "Cambiar consumo anual estimado",
    "BACK": "Volver",
    "ESTIMATED_CONSUMPTION_UPDATED": "Consumo anual estimado actualizado correctamente.",
    "ESTIMATED_CONSUMPTION_UPDATED_ERROR": "Error al actualizar el consumo anual estimado.",
    "This contract number already exists": "Este número de contrato ya existe",
    "DUPLICATE_CONTRACT": "Duplicar contrato",
    "DUPLICATE": "Duplicar",
    "Contract successfully duplicated": "Contrato duplicado correctamente.",
    "Contract error duplicating": "Error duplicando el contrato. Inténtalo de nuevo.",
    "SIGN_DATE": "Fecha de firma",
    "SENT_DATE": "Fecha de envío",
    "APP_DATE": "Fecha de solicitud",
    "PRODUCT_RATE": "Tarifa precios",
    "Product rate not in force": "No vigentes",
    "Filter by": "Filtrar por",
    "Date from": "Desde",
    "Date to": "Hasta",
    "Sign date range": "Fecha de firma",
    "Sent date range": "Fecha de envío",
    "App date range": "Fecha de solicitud",
    "Expiration date range": "Fecha de finalización",
    "Activation date range": "Fecha de activación",
    "Without Filter": "No filtrar",
    "Date range": "Fechas",
    "CUSTOMER": "Cliente",
    "MANAGER": "Manager",
    "CHANGE_RATE": "Cambiar tarifa",
    "NEW_CONTRACT": "Nuevo contrato",
    "CLOSE_PAGE": "Existen cambios sin guardar en la creación de contratos.",
    "ALL_SELECTED": "Todos",
    "FEES": "Honorarios",
    "ATTACHED_FILES": "Archivos",
    "COMMISSION": "Comisión",
    "WITHOLDING": "IRPF",
    "LIST_FEES": "Listado de honorarios",
    "FROM": "Desde",
    "TO": "Hasta",
    "DATE": "Fecha",
    "INVOICE": "Factura",
    "TAX": "Impuesto",
    "TOTAL": "Total",
    "AGREEMENT MANAGES CONTRACT": "Convenio gestiona contrato",
    "CHANGE_AGREEMENT": "Cambiar Convenio",
    "DATE_CHANGE_AGREEMENT": "Fecha de cambio de Convenio",
    "SET_TO_INVOICE_CONTRACTS_SUCCESS": "La facturación automática de contratos se ha puesto en cola",
    "SET_TO_INVOICE_CONTRACTS_ERROR": "Se ha producido un error en la tarea de facturación de contratos",
    "AUTO_CONTRACT_INVOICE": "Facturación automática de contratos",
    "OBSERVATIONS": "Observaciones",
    "VIEW": "Ver",
    "TO_PRINT": "Se debe imprimir",
    "CHANGE_DATES": "Cambiar fechas de activación del contracto",
    "DATE_CONTRACT_FOR_SUPPLY_UPDATED_SUCCESS": "Se han actualizado las fechas de activación del contrato",
    "DATE_CONTRACT_FOR_SUPPLY_UPDATED_ERROR": "Error al actualizar las fechas de activación del contrato",
    "ACTIVATION_DATE_FROM": "Desde",
    "ACTIVATION_DATE_TO": "Hasta",
    "INVOICES": "Facturas",
    "INVOICES_LIST": "Listado de facturas para el contrato:",
    "XML_FROM": "Desde",
    "XML_TO": "Hasta",
    "XML_DATE": "Fecha de importación",
    "CUSTOMER_CREATED": "Cliente creado correctamente.",
    "START_DATE": "Fecha de alta",
    "EXPIRATION_DATE": "Fecha de expiración",
    "DELETED": "Borrados ",
    "UPDATED": "Actualizados: ",
    "CONTRACT_AGREEMENTS": " convenios en Contratos",
    "ERROR_REQUEST": "Se ha producido un error",
    "CONTRACT_RATES": "  tarifas de contratos.",
    "CONTRACT_STATUS": "  estados en Contratos.  ",
    "CONTRACT_STATUS_ERROR_NUMBER": "  Contratos no actualizados por no tener el cliente número de teléfono.",
    "ERROR_PERMISSIONS": "No tienes permisos para realizar la acción seleccionada sobre el/los contratos, o no es posible realizar el cambio de estado deseado.",
    "CHANGE_PRODUCT_RATE": "Cambiar tarifa de precios",
    "CONTRACT_PRODUCT_RATES": "  tarifas de precios en contratos.",
    "ITEM_TO_BE_INVOICED": "A facturar",
    "ITEM_TO_BE_INVOICED_LIST": "Elementos pendiente de facturar para el contrato: ",
    "CREATE_POSTPONEMENT": "Crear aplazamiento",
    "CREATE_POSTPONEMENT_TOTAL": "Crear aplazamiento para un total de : ",
    "SHOW_DEFERRED_INVOICES": "Ver facturas aplazables",
    "SHOW_ALL_INVOICES": "Ver todas las facturas",
    "RECEIPTS_AMOUNT": "Importe de recibos",
    "CREATE_RECEIPTS": "Crear recibos",
    "REASON": "Razón",
    "RESET": "Reiniciar",
    "CREATE_DEFERMENT": "Crear aplazamiento",
    "DEFERMENT_CREATED_OK": "Aplazamiento creado correctamente",
    "invoice data uploaded": "Los datos de factura se guardaron correctamente",
    "INVOICE_DATA": "Datos de factura y factura electrónica",
    "INVOICE_OBSERVATIONS": "Observaciones de factura",
    "administrative data uploaded": "Los datos de factura electrónica se guardaron correctamente",
    "INDEXED": "Indexado",
    "NORMAL": "Normal",
    "CONTRACT_TYPE": "Tipo de facturación",
    "SHOW_RETURNED_INVOICES": "Alguna vez devueltas",
    "Regularity_default_choice": "-- Seleccione la periodicidad --",
    "PRODUCT_RATES": "Tarifas de precios",
    "ACTIVATION_DATE": "Fecha de activación",
    "NEW_PRODUCT_RATE": "Nueva tarifa de precios",
    "NEW_PRODUCT_SERVICE": "Nuevo servicio",
    "ADD": "Añadir",
    "CANCEL": "Cancelar",
    "PRODUCT_RATE_ADDED": "Cambio de tarifa añadido",
    "ERROR_ADDING_PRODUCT_RATE": "Error al añadir cambio de tarifa",
    "PRODUCT_RATE_DELETED": "Cambio de tarifa eliminado",
    "ERROR_DELETING_PRODUCT_RATE": "Error al eliminar cambio de tarifa",
    "CONFIRM_DELETE": "¿Quieres eliminar este cambio de tarifa de contrato?",
    "UPDATE": "Actualizar",
    "CONFIRM_UPDATE": "¿Actualizar?",
    "PRODUCT_RATE_UPDATED": "Tarifa actualizada",
    "PRODUCT_SERVICE_UPDATED": "Servicio actualizado",
    "PRODUCT_SERVICE_ADDED": "Servicio añaido",
    "ERROR_PRODUCT_RATE_ADDED": "Error añadiendo servicio",
    "ERROR_UPDATING_PRODUCT_RATE": "Error al actualizar tarifa",
    "CONTRACT_FULLNAME": "Nombre",
    "CONTRACT_SURNAME_1": "Apellido 1",
    "CONTRACT_SURNAME_2": "Apellido 2",
    "ADDRESS_NUMBER": "Número",
    "ADDRESS_EXTENSION": "Extensión",
    "IE_TYPE": "Impuesto eléctrico",
    "EXTRA_EMAILS": "Emails asociados al contrato",
    "WEB_ACCESS_RECEIVE_INVOICES": "Acceso Web y recepción de facturas",
    "WEB_ACCESS_ONLY": "Sólo Acceso Web",
    "ENABLE_CONTRACT_EDITION": "Desbloquear edición",
    "UPDATE_CONTRACT": "Actualización de contratos",
    "CONFIRM_UPDATE_CONTRACT": "A continuación debe introducir el motivo por el cual va a modificar el contrato.",
    "AGREEMENT_MANDATORY": "El convenio es obligatorio",
    "NO_RESULTS": "No hay resultados",
    "PTO_SUMINISTRO": "Pto. de suministro",
    "AGREEMENTS": "Convenios",
    "AGREEMENT_ADDED": "Convenio añadido",
    "ERROR_ADDING_AGREEMENT": "Error al añadir convenio",
    "NEW_AGREEMENT": "Nuevo convenio",
    "STARTED_DATE": "Fecha de comienzo",
    "CONFIRM_DELETE_AGREEMENTS": "¿Eliminar convenio?",
    "CONFIRM_UPDATE_AGREEMENTS": "¿Actualizar convenio?",
    "AGREEMENT_DELETED": "Convenio eliminado",
    "AGREEMENT_UPDATED": "Convenio actualizado",
    "SUPPLY_CREATE_SUCCESS": "Punto de suministro creado con éxito",
    "RATE_PRICE_NOT_MATCH": "La tarifa de precios seleccionada no es válida para la tarifa selecciona.",
    "INVALID_VALUE": "Valor no válido.",
    "ZIP_LENGTH": "Debe incluir sólo 5 dígitos",
    "REJECTED": "Rechazado",
    "CHANGE": "Cambio",
    "INCIDENCE": "Corte por impago",
    "CREATED": "Creadas: ",
    "DATE_CHANGE_PRODUCT_RATE": "Fecha de cambio",
    "FINISH_DATE": "Fecha de baja",
    "PROGRAMMING_PRODUCT_RATE_RENOVATION": "Programar Renovación",
    "POWER_T20_ERROR": "La potencia debe de ser inferior o igual a 10kW.",
    "POWER_T21_ERROR": "La potencia debe de ser mayor de 10kW y menor o igual a 15kW.",
    "POWER_T3X_ERROR": "La potencia debe de ser mayor a 15kW e inferior a 450 KW.",
    "POWER_T6X_ERROR": "La potencia debe de ser superior a 450 KW.",
    "SEARCHING": "Buscando....",
    "EDIT": "Editar",
    "SUPPLY_UPDATE_SUCCESS": "Punto de suministro actualizado con éxito",
    "CREATE": "Crear",
    "PAYMENT_VAT_ID": "DNI/CIF",
    "NO_CUPS": "No hay CUPS",
    "SEPA": "Referencia SEPA",
    "SEPA_CODE_REQUIRED": "La referencia SEPA es obligatoria",
    "NUMERIC_FIELD": "Este campo solo admite valores numéricos",
    "SEPA_LENGTH": "Debe tener 20 dígitos",
    "PRINT_INVOICES": "Impresión de facturas",
    "CONFIRM_BULK_UPDATE": "¡Estos datos se actualizarán en todos los contratos seleccionados!",
    "CONTRACTS_UPDATED": "Todos los contratos se han actualizado correctamente",
    "ONLY_MANAGED": "Solo contratos gestionados",
    "POSTAL_DATA": "Datos de correspondencia",
    "BULK_UPDATE": "Actualización masiva",
    "MULTIPOINT_CONTRACT": "Contrato multipunto",
    "NAME": "Nombre",
    "MAIN_EMAIL": "Email principal",
    "SECONDARY_EMAIL": "Email secundario",
    "CIF": "CIF",
    "PHONE": "Teléfono",
    "IBAN": "IBAN",
    "UNIQUE_ACCOUNT": "Número de cuenta único",
    "SEPA_GENERATED_BY_CIF": "SEPA generada por CIF",
    "ZIP": "ZIP",
    "Download multipoint contract success": "Contrato multipunto en descarga",
    "Download multipoint contract error": "Error al generar contrato multipunto",
    "MULTIPOINT_ADVICE": "No puede haber convenios distintos para los contratos seleccionados. Para cada tarifa en los contratos seleccionados solo puede haber una tarifa de precios",
    "UNSUBSCRIBE_CONTRACT": "Solicitar baja",
    "EVENT_CREATED_FOR_CONTRACT": "Se ha creado el evento de Solicitud de Baja del contracto",
    "Update customer": "Cambio de titular",
    "CURRENT_CUSTOMER_ADVICE": "El cliente asociado actualmente es {{ name }} {{ surname_1 }} {{ surname_2 }}, con DNI/NIF {{ vat_id }}.",
    "GOTO": "Ir a...",
    "DOWNLOAD_PDF_RENEW_DEFAULT": "PDF por defecto (renovación)",
    "DOWNLOAD_PDF_RENEW_SIGNED": "PDF por firmado (renovación)",
    "EVENT_MAXIMETRE_CREATED_FOR_CONTRACT": "Se ha creado el evento de Solicitud de Maxímetro del contracto",
    "MAXIMETRE_CONTRACT": "Solicitar Maxímetro",
    "DOWNLOAD_PDF_DEFAULT_WITHOUT_SUPPLY_INFO": "PDF por defecto sin Pto. suministro",
    "DOWNLOAD_PDF_SIGNED_WITHOUT_SUPPLY_INFO": "PDF con firma sin Pto. suministro",
    "CONTRACT_BANK_DEFAULT_NUMBER": "  Contratos no actualizados por tener el número de cuenta bancaria a ceros.",
    "UNSUBSCRIBE_QUESTION": "¿Solicitar Baja?",
    "SELECT_MOTIVE": "Seleccionar Motivo",
    "INVOICED_MODE_REQUIRED": "El tipo de facturación es obligatoria",
    "SELECT_INVOICE_MODE": "Seleccionar modo facturación",
    "RECEIVER_CONTRACT_REFERENCE": "Referencia del contrato del receptor",
    "FILE_REFERENCE": "Referencia archivo",
    "TO_PENDING": "A pendiente de datos",
    "CUSTOMER_DATA": "Datos de cliente",
    "BULK_UPDATE_QUEUED": "La actualización masiva se ha puesto en cola",
    "BULK_UPDATE_REJECTED": "La actualización masiva contiene errores",
    "PENDING_RENEW": "Pendientes de renovación",
    "PENDING_DATE": "Fecha de pendiente",
    "PENDING_DATE_ATR": "Fecha de pendiente ATR",
    "This contract number doesn't exists": "Ese contrato no existe",
    "POINT_TYPE_REQ": "Tipo punto de medida requerido",
    "INVALID_CITY_VALUE": " La población no es válida",
    "DISCOUNT": "Reducción (%)",
    "NOT_TO_UPDATE": "No Actualizadas: ",
    "ADMINISTRATIVE_CHANGES_CONTRACT": "Solicitar Cambios administrativos",
    "CUSTOMER_CHANGES_CONTRACT": "Solicitar Correcciones de cliente",
    "INVOICES_PERIODICITY_CHANGES_CONTRACT": "Solicitar Cambio periodicidad facturación",
    "EVENT_ADMINISTRATIVE_CHANGES_CREATED_FOR_CONTRACT": "Se ha creado el evento de Solicitud de Cambios administrativos del contracto",
    "EVENT_CUSTOMER_CHANGES_CREATED_FOR_CONTRACT": "Se ha creado el evento de Solicitud de Correcciones de datos que identifican al cliente del contracto",
    "EVENT_INVOICE_PERIODICITY_CHANGES_CREATED_FOR_CONTRACT": "Se ha creado el evento de Solicitud de Cambio periodicidad facturación del contracto",
    "SUB_STATUS": "Sub-estado",
    "TO_READY": "A preparado",
    "TO_DATA_NEEDED": "A pendiente de datos",
    "TO_DATA_NEEDED_ATR": "A pendiente de datos ATR",
    "CLOSE_INVOICE_DATE": "Cerrar periodo facturación",
    "CLOSE_INVOICE_DATE_OF": "¿Fecha cierre factura de estos",
    "ESTIMATED_FINISH_DATE": "¿Fecha fin factura estimada de los",
    "CLOSE_INVOICEQ": "¿Desea cerrar la facturación estimada de los",
    "CLOSE_PERIOD": "Cerrar factura",
    "READY": "Preparado",
    "DO_NOT_PROCESS": "No tramitar",
    "DATA_NEEDED": "Pendiente de datos",
    "DATA_NEEDED_ATR": "Pendiente de datos ATR",
    "CHANGE_NOTICE": "Aviso cambio",
    "UNPAID": "Impago",
    "CHANGE_NUMBER": "Cambio numeración",
    "RESIGNATION": "Baja voluntaria",
    "CHANGE_COMMERCIAL": "Cambio comercializador",
    "MANAGEMENT_CHANGE": "Cambio de cartera",
    "TO_CONNECT": "A acometida",
    "CONNECTION_STATUS": "Acometida",
    "CLOSE_ESTIMATED_PERIOD": "Cerrar facturación estimada",
    "Close estimated invoice Task Created": "Tarea de generación factura de cierre periodo estimado creada correctamente",
    "Close estimated invoice Task Creation ERROR": "Tarea de generación factura de cierre periodo estimado fallida",
    "CREATE_ESTIMATED_INVOICE": "Generar factura estimada",
    "GENERATE_INVOICE": "Generar factura",
    "Contracts will be invoiced with the given close date": "Los contratos serán esimados con la fecha de cierre seleccionada",
    "CHANGE_INVOICING_MODE": "Cambiar modo facturación",
    "CONTRACT_CAN_NOT_BE_PROCESS": "Contratos que no pueden no procesados",
    "CONTRACT_PERMS_NOT_PROCESS": "Contratos no tramitados por no disponer de permisos",
    "ATR_COMMENTS": "Indicaciones para ATR",
    "EXCLUDED_PRODUCT": "Productos Excluidos",
    "DENOMINATION": "Denominación",
    "MAX_EXCLUSION": "Exclusiones Máximas",
    "COUNT_EXCLUSION": "Conteo de Exclusión",
    "UPDATED_DATE": "Fecha actualización",
    "CONFIRM_DELETE_EXCLUDED_PRODUCTS": "Confirmar borrado de producto excluido",
    "CONFIRM_UPDATE_EXCLUDED_PRODUCTS": "Confirmar actualizacion de producto excluido",
    "EXCLUDED_PRODUCT_UPDATED": "Producto excluido actualizado",
    "EXCLUDED_PRODUCT_DELETED": "Producto excluido borrado",
    "EXCLUDED_PRODUCT_ADD": "Producto excluido generado",
    "NEW_EXCLUDED_PRODUCT": "Nuevo producto excluido",
    "PRODUCT_SERVICE": "Servicio",
    "PRODUCT_SERVICES_ASSOCIATED": "Servicios asociados",
    "CONTRACT_INVOICE_GENERATION_MODE": "Modo generación de factura",
    "CHANGE_CONTRACT_INVOICE_GENERATION_MODE": "Cambiar Modo generación de factura",
    "VALIDITY_DATE": "Fecha de validez",
    "ADD_ITEM_TO_BE_INVOICED": "Añadir Item",
    "ITEM_TO_BE_INVOICE_ADDED": "Item añadido con éxito",
    "CONFIRM_DELETE_ITEM": "¿Quieres eliminar este item?",
    "ITEM_TO_BE_INVOICE_DELETED": "El Item ha sido eliminado con éxito",
    "ITEM_TO_BE_INVOICE_UPDATED": "El Item ha sido actualizado con éxito",
    "REMITTANCE_DAY": "Día a remesar",
    "DEFAULT_REMITTANCE_DAY": "Día de remesa por defecto",
    "CHANGE_REMITTANCE_DAY": "Cambiar día de remesa del contrato",
    "COMPLAINTS_LIST": "Listado de reclamaciones para el contrato:",
    "COMPLAINT_NUMBER": "Nº Reclamación",
    "COMPLAINT_SUBTYPE": "Subtipo",
    "COMPLAINT_DATE": "Fecha creación",
    "COMPLAINT_STATUS": "Estado",
    "INVOICE_COVER": "Carátula facturas",
    "M1_EVENT_GENERATION": "¿Desea generar evento ATR M1 asociado a este cambio de tarifa?",
    "NEW_PRODUCT_TO_INVOICE": "Nuevo producto a facturar",
    "TO_ANOMALY_FRAUD": "A Anomalía / Fraude",
    "ANOMALY_FRAUD": "Anomalía / Fraude",
    "ADD_PRODUCT_NOTE": "En los productos VARS y PENALTIES este texto se mostrará en la línea de factura",
    "QUANTITY": "Cantidad",
    "ADD_TO_CONTRACT": "Adjuntar al contrato",
    "DOWNLOAD_SIGNED_PDF": "Contrato firmado",
    "BULK_CONTRACT_DOWNLOAD_CSV": "La generación del informe se ha puesto en cola",
    "FIXED_DATE": "Fecha Fija",
    "CONTRACT_FIXED_DATE": "Nº Contratos a estado ENVIADO por fecha fija",
    "COMMENT": "Comentario",
    "ADD_COMMENT": "Añadir comentario",
    "Bulk_Comment": "Creación de Comentarios masivo",
    "WITHDRAWAL_CONTRACT": "Solicitar desistimiento",
    "WITHDRAWAL_QUESTION":"¿Solicitar desistimiento?",
    "WITHDRAWAL_NOT_IN_DATE_QUESTION": "La fecha de activación del contrato es superior a 15 días.",
    "EVENT_WITHDRAWAL_CREATED_FOR_CONTRACT": "Se ha creado el evento de Desistimiento del contracto",
    "INVALID_PRODUCT_RATE": "La tarifa de precios seleccionada no tiene definidos todos los precios para la tarifa de peaje",
    "ERROR_CHECKING_PRODUCT_RATE": "Se ha producido un error al validar la tarifa de peaje seleccionada para el contrato",
    "SELECT_PLACE": "Seleccionar Lugar de firma",
    "SELECT_PLACE_TO_DOWNLOAD_PDF": "Seleccionar el lugar para descargar el Pdf",
    "CONTRACT_DATE_MANUAL": "Fecha Contrato (dejar en blanco si no se desea utilizar)",
    "FEE_PRICES": "Rangos de honorarios",
    "Customer": "Cliente",
    "Supply": "Pto. de suministro",
    "Contract": "Contrato",
    "CONTRACT_SELF_CONSUMPTION_TYPE": "Modo de autoconsumo",
    "ESTIMATED": "Contrato facturado en modo estimado",
    "PRODUCT_PRICES_ASSOCIATED": "Precios de productos por contrato",
    "PRODUCT_PRICE_DELETED": "Precio eliminado",
    "PRODUCT_PRICE_UPDATED": "Precio actualizado",
    "PRODUCT_PRICE_ADDED": "Precio creado",
    "CONFIRM_DELETE_PRICE": "¿Borrar precio?",
    "ESTIMATE_INVOICE": "Generar facturación estimada",
    "CONFIRM_INVOICE_GENERATION": "Se va a generar una factura estimada por cada contrato seleccionado",
    "ESTIMATED_PROFILED": "Contrato facturado en modo estimado perfilado",
    "CREATION_DATE": "Fecha de creación",
    "RELATED_CONTRACTS": "Contratos relacionados",
    "NO_RELATED_CONTRACTS": "No hay contratos relacionados",
    "ADD_RELATED_CONTRACT": "Añadir contrato relacionado",
    "CONTRACT_ID": "ID de contrato",
    "CONFIRM_DELETE_RELATED": "¿Quieres eliminar este contrato relacionado?",
    "CONFIRM_DELETED_RELATED": "Se ha borrado con éxito",
    "CONFIRM_UPDATE_RELATED": "Se ha actualizado con éxito",
    "ERROR_CONFIRM_ADD_RELATED": "No se ha podido añadir el contrato relacionado",
    "CONFIRM_ADD_RELATED": "Se ha añadido con éxito",
    "ACTIVITY_CESSATION": "Cese de Actividad",
    "END_CONTRACT": "Fin de contrato de energía",
    "SUPPLY_CUT": "Corte de suministro",
    "UNPAID_CONTRACT": "Baja por impago",
    "NON_UNPLUG_CONTRACT":  "Baja sin desconexión definitiva de instalaciones",
    "REGULARIZE": "Regularizar",
    "REGULARIZE_INSTRUCTIONS": "Selecciona la fecha de inicio y fin del periodo de facturación que quieres regularizar.",
    "REGULARIZE_OK": "Solicitud de regularización creada. Comprueba las tareas en ejecución.",
    "REGULARIZE_ERROR": "Error al crear la solicitud de regularización.",
    "STRICT_DATES": "Usar fechas estrictas",
    "ADJUST_CONTRACTQ": "¿Desea ajustar la facturación de ",
    "ADJUST_CONTRACTS": "Ajustar contratos",
    "ADJUST_START_DATE": "Fecha inicio ajuste",
    "OPTIONAL": "Opcional",
    "DUPLICATE_CONTRACTS": "Duplicar contratos",
    "INITIAL_ID": "ID inicial",
    "Bulk_duplicate_contracts": "Solicitud de duplicación de contratos masiva creada. Comprueba las tareas en ejecución",
    "task_creation_error": "Ha fallado la solicitud de la tarea. Prueba más tarde.",
    "DUPLICATE_SERVICES": "Duplicar servicios",
    "DUPLICATE_SPECIAL_PRICES": "Duplicar precios especiales",
    "NEXT_INVOICE": "Siguiente factura",
    "CANCEL_UNSUBSCRIBE_REQUEST": "Cancelar Solicitud de baja",
    "EVENT_CANCEL_UNSUBSCRIBE_FOR_CONTRACT": "Se ha creado el evento de Cancelación de solicitud de baja",
    "DELETE_SELECTED_ITEMS": "Borrar objetos seleccionados",
    "RELATED_DATA": "Información relacionada",
    "FORCE_CONTRACT_STATUS": "Forzar estado de los contratos",
    "Bulk_force_contract_status": "Creada la tarea de forzado de estado de contratos",
    "Bulk_force_contract_task_error": "Error creando la tarea de forzado de estado de contratos",
    "CHANNEL": "Canal contratación",
    "SELECT_CHANNEL": "Seleccionar Canal",
    "CHANNEL_REQUIRED": "El canal es requerido",
    "PRODUCTS_RATES_INFO": "Información Tarifa de Precios",
    "PRICE_COVERAGE_CODE": "Código de Cobertura Precios",
    "PRICE_COVERAGE_VALUE": "Precio de Cobertura Precios",
    "GENERATE_FLAT_RATE_INVOICES": "Generar Facturas de Tarifa Plana",
    "GENERATE_FLAT_RATE_INVOICESQ": "¿Generar facturas de ",
    "FLAT_RATE_DATE": "Fecha tarifa plana",
    "Generate Flat Rate Invoices Task Created": "Tarea de generación de facturas de tarifa plana creada correctamente",
    "Generate Flat Rate Invoices Task Creation ERROR": "Error creando tarea de generación de facturas de tarifa plana",
    "Flat rate": "Tarifa plana",
    "Flat rate price": "Cuota tarifa plana (Base imponible)",
    "INVOICE_GENERATION_MODE": "Modo generación de factura",
    "ENERGY_PRICE": "Precio Energía",
    "POWER_PRICE": "Precio Potencia",
    "RATE_AND_PRODUCT_RATE_PRICES": "Precios para la Tarifa y Tarifa de Precios seleccionada",
    "ATR_REQUEST_TITLE": "ATR",
    "ATR_REQUEST_LIST": "Listado de procesos ATR para el contrato: ",
    "PROVIDER_INVOICE": "Factura de Proveedor",
    "ORIGIN": "Origen",
    "VALUE": "Valor",
    "TYPE": "Tipo",
    "CONTRACT_OUTGOING_ENERGY_INVOICING_MODE": "Modo facturación autoconsumo"
  }
}
