import angular from "angular";
import ngResource from "angular-resource";

// Controllers
import {AgreementDataFormCtrl} from './controllers/agreementDataFormCtrl';
import {ChangeContractForSupplyDatesCtrl} from './controllers/ChangeContractForSupplyDatesCtrl';
import {ChangeEstimatedConsumptionCtrl} from './controllers/ChangeEstimatedConsumptionCtrl';
import {ConsumptionsListFormCtrl} from './controllers/ConsumptionsListFormCtrl';
import {ContractDataFormCtrl} from './controllers/ContractDataFormCtrl';
import {ContractDetailCtrl} from './controllers/ContractDetailCtrl';
import {ContractExcludedProducts} from './controllers/ContractExcludedProducts';
import {ContractExcludedProductsDetail} from './controllers/ContractExcludedProductsDetail';
import {ContractFeePricesCtrl} from './controllers/ContractFeePricesCtrl';
import {ContractForAgreementCtrl} from './controllers/ContractForAgreementCtrl';
import {ContractForAgreementDetailCtrl} from './controllers/ContractForAgreementDetailCtrl';
import {ContractListCtrl} from './controllers/ContractListCtrl';
import {ContractProductRatesCtrl} from './controllers/ContractProductRatesCtrl';
import {ContractRelatedCtrl} from './controllers/ContractRelatedCtrl';
import {ContractProductRatesDetailCtrl} from './controllers/ContractProductRatesDetailCtrl';
import {ContractsDetailTabsCtrl} from './controllers/ContractsDetailTabsCtrl';
import {ContractsFeesCtrl} from './controllers/ContractsFeesCtrl';
import {CustomerFormCtrl} from './controllers/CustomerFormCtrl';
import {DeferInvoicesCtrl} from './controllers/DeferInvoicesCtrl';
import {DraftCreationCtrl} from './controllers/DraftCreationCtrl';
import {DuplicateContractCtrl} from './controllers/DuplicateContractCtrl';
import {InvoicesForContractCtrl} from './controllers/InvoicesForContractCtrl';
import {ItemToBeInvoicedForContractCtrl} from './controllers/ItemToBeInvoicedForContractCtrl';
import {ItemToBeInvoicedForContractDetailCtrl} from './controllers/ItemToBeInvoicedForContractDetailCtrl';
import {PaymentDataFormCtrl} from './controllers/PaymentDataFormCtrl';
import {SupplyFormCtrl} from './controllers/SupplyFormCtrl';
import {ContractProductPriceCtrl} from "./controllers/ContractProductPriceCtrl";
import {ContractProductPriceDetailCtrl} from "./controllers/ContractProductPriceDetailCtrl";
import {ContractProductServiceCtrl} from "./controllers/ContractProductServiceCtrl";
import {ContractProductServicesDetailCtrl} from "./controllers/ContractProductServicesDetailCtrl";
import {AtrRequestListCtrl} from "./controllers/AtrRequestCtrl";

// Services
import {ContractAPI} from './services/ContractAPI';
import {ContractDetail} from './services/ContractDetail';
import {ContractForAgreement} from './services/ContractForAgreement';
import {CreateContract} from './services/CreateContract';
import {CreateDeferredInvoices} from './services/CreateDeferredInvoices';
import {ExcludedProductApi} from './services/ExcludedProductApi';


// Directives
import {contractsAgreement} from './directives/ContractAgreement';
import {contractCloseEstimated} from './directives/ContractCloseEstimated';
import {adjustContract} from './directives/AdjustContract';
import {generateFlatRateInvoices} from './directives/GenerateFlatRateInvoices';
import {contractCloseInvoice} from './directives/ContractCloseInvoice';
import {contractDeletion} from './directives/ContractDeletion';
import {contractExtraEmails} from './directives/ContractExtraEmails';
import {contractInvoicingGenerationMode} from './directives/ContractInvoicingGenerationMode';;

import {contractListFilters} from './directives/ContractListFilters'

import {contractListFiltersToInvoice} from './directives/ContractListFiltersToInvoice';
import {contractsProductRate} from './directives/ContractProductRate';
import {contractsAddProductToBeInvoiced} from './directives/ContractsAddProductToBeInvoiced';
import {contractsAddExcludedProduct} from './directives/ContractsExcludedProduct';
import {contractsRate} from './directives/ContractsRate';
import {contractStatus} from './directives/ContractStatus';
import {contractsTypeInvoices} from './directives/ContractTypeInvoices';
import {contractUnsubscribe} from './directives/ContractUnsubscribe';
import {contractBulkUnsubscribe} from './directives/ContractsBulkUnsubscribe';
import {contractWithdrawal} from './directives/ContractWithdrawal';
import {renewProductRate} from './directives/renewProductRate';


const contracts = angular.module('contracts', ['pascalprecht.translate', ngResource, 'powr']);

contracts.config(['$translateProvider', function($translateProvider){
    $translateProvider.translations('es', require('../i18n/es.json'))
    $translateProvider.translations('en', require('../i18n/en.json'))
}])

contracts.factory('Contract', ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/contracts/:id/', { id: '@id' },
        {
            'update': { method:'PUT' },
            'patch': { method:'PATCH' }
        }
    );
}]);

contracts.factory('ContractForAgreement', ['$resource', function ($resource) {
    return $resource(API_URL + '/api/1.0/contractforagreement/', {},
        {
            patch: {
                method: 'patch'
            }
        });
}]);

contracts.constant('CONTRACT_STATUS', [
    {code: 'DRF', label: 'Borrador'},
    {code: 'ACT', label: 'Activo'},
    {code: 'INA', label: 'Inactivo'}
]);

contracts.constant('CONTRACT_SUB_STATUS', {
    'DRF': [
        {code: 'RDY', label: 'Preparado'},
        {code: 'SNT', label: 'Enviado'},
        {code: 'SGN', label: 'Firmado'},
        {code: 'DNP', label: 'No tramitar'},
        {code: 'APP', label: 'Solicitud'},
        {code: 'DND', label: 'Pendiente de datos'},
        {code: 'DNA', label: 'Pendiente de datos ATR'},
        {code: 'CON', label: 'Acometida'}

    ],
    'ACT': [
        {code: 'ACT', label: 'Activo'},
        {code: 'CHN', label: 'Aviso Cambio'},
        {code: 'UNP', label: 'Impago'},
        {code: 'DND', label: 'Pendiente de datos'},
        {code: 'DNA', label: 'Pendiente de datos ATR'},
        {code: 'CON', label: 'Acometida'},
        {code: 'ANO', label: 'Anomalía/Fraude'},
        {code: 'ORP', label: 'Pendiente de original'}
    ],
    'INA': [
        {code: 'UNP', label: 'Impago'},
        {code: 'CHA', label: 'Cambio numeración'},
        {code: 'RSG', label: 'Baja Voluntaria'},
        {code: 'CHC', label: 'Cambio comercializador'},
        {code: 'CON', label: 'Acometida'}
    ]


});
contracts.constant('CONTRACT_REGULARITIES', [
    {code: 'MES', label: 'Mensual'},
    {code: 'BIM', label: 'Bimestral'}
]);

contracts.constant('CONTRACT_ORIGINS', [
    {code: "A301", label: "A3.01 Alta Directa"},
    {code: "C101", label: "C1.01 Cambio de comercializador sin cambios"},
    {code: "C201A", label: "C2.01 Cambio de comercializador con cambios - Potencia"},
    {code: "C201B", label: "C2.01 Cambio de comercializador con cambios - Tarifa"},
    {code: "C201C", label: "C2.01 Cambio de comercializador con cambios - Titular"},
    {code: "C201D", label: "C2.01 Cambio de comercializador con cambios - Titular + Potencia"},
    {code: "C201E", label: "C2.01 Cambio de comercializador con cambios - Titular + Tarifa"},
    {code: "C201F", label: "C2.01 Cambio de comercializador con cambios - Potencia + Tarifa"},
    {code: "C201G", label: "C2.01 Cambio de comercializador con cambios - Titular + Potencia + Tarifa"},
    {code: "REN", label: "Renumeración"},
    {code: "RENTT", label: "Renumeración. Cambio de Titular (Interno)"},
    {code: "RENTTA", label: "Renumeración. Cambio de Titular (Distribuidora)"},
    {code: "RENTA", label: "Renumeración. Cambio de Tarifa"},
    {code: "RENPP", label: "Renumeración. Cambio de Potencia"},
    {code: "RENPT", label: "Renumeración. Cambio de Potencia y Tarifa"},
    {code: "RENPTT", label: "Renumeración. Cambio de Titular, Potencia y Tarifa"},
]);

contracts.constant('CONTRACT_ORIGINS_TYPES', [
    {code: "REN", label: "Renumeración"},
    {code: "RENTTA", label: "Renumeración. Cambio de Titular (Distribuidora)"},
    {code: "RENTA", label: "Renumeración. Cambio de Tarifa"},
    {code: "RENPP", label: "Renumeración. Cambio de Potencia"},
    {code: "RENPT", label: "Renumeración. Cambio de Potencia y Tarifa"},
    {code: "RENPTT", label: "Renumeración. Cambio de Titular, Potencia y Tarifa"},
    {code: "C101", label: "C1.01 Cambio de comercializador sin cambios"},
    {code: "C201A", label: "C2.01 Cambio de comercializador con cambios - Potencia"},
    {code: "C201B", label: "C2.01 Cambio de comercializador con cambios - Tarifa"},
    {code: "C201C", label: "C2.01 Cambio de comercializador con cambios - Titular"},
    {code: "C201D", label: "C2.01 Cambio de comercializador con cambios - Titular + Potencia"},
    {code: "C201E", label: "C2.01 Cambio de comercializador con cambios - Titular + Tarifa"},
    {code: "C201F", label: "C2.01 Cambio de comercializador con cambios - Potencia + Tarifa"},
    {code: "C201G", label: "C2.01 Cambio de comercializador con cambios - Titular + Potencia + Tarifa"}
]);


contracts.constant('IE_TYPES', [
    {code: 'NOR', name: 'Normal'},
    {code: 'RED', name: 'Reducido'}
]);


contracts.constant('CONTRACT_MODE', [
    {code: 'NOR', label: 'Normal'},
    {code: 'IND', label: 'Indexada'},
    {code: 'INB', label: 'Indexada sin elevar a barras'},
    {code: 'INM', label: 'Indexada Mixta'},
    {code: 'Q11', label: 'Q101'},
    {code: 'EST', label: 'Estimada'},
    {code: 'EPR', label: 'Estimado Perfilado'},
    {code: 'FRT', label: 'Tarifa Plana'}
]);

contracts.constant('CONTRACT_MODE_MASS_UPDATE', [
    {code: 'NOR', label: 'Normal'},
    {code: 'EST', label: 'Estimada'},
    {code: 'EPR', label: 'Estimado Perfilado'},
    {code: 'CLS', label: 'Normal - Con eliminacion de estimadas pendientes.'},
    {code: 'IND', label: 'Indexada'}
]);

contracts.constant('CONTRACT_INVOICING_GENERATION_MODE', [
    {code: 'DEF', label: 'Defecto'},
    {code: 'UNF', label: 'Unificación de energía con mismo precio'},
    {code: 'REG', label: 'Regularización de facturas en siguiente Estimada'},
    {code: 'FRT', label: 'Tarifa Plana'}
]);

contracts.constant('CONTRACT_SELF_CONSUMPTION_MODE', [
    {code: true, label: 'Con autoconsumo'},
    {code: false, label: 'Sin autoconsumo'}
]);

contracts.constant('CONTRACT_OUTGOING_ENERGY_INVOICING_MODE', [
    {code: 'NOR', label: 'Normal'},
    {code: 'IND', label: 'Indexado'}
]);

contracts.constant('CONTRACT_CHANNELS', [
    {code: 'T', label: 'Teléfono'},
    {code: 'O', label: 'Online'},
    {code: 'D', label: 'Presencial Domiciliario'},
    {code: 'P', label: 'Presencial No Domiciliario'},
    {code: 'R', label: 'Resto de canales'},
]);

contracts
    .controller('AgreementDataFormCtrl', AgreementDataFormCtrl)
    .controller('ChangeContractForSupplyDatesCtrl', ChangeContractForSupplyDatesCtrl)
    .controller('ChangeEstimatedConsumptionCtrl', ChangeEstimatedConsumptionCtrl)
    .controller('ConsumptionsListFormCtrl', ConsumptionsListFormCtrl)
    .controller('ContractDataFormCtrl', ContractDataFormCtrl)
    .controller('ContractDetailCtrl', ContractDetailCtrl)
    .controller('ContractExcludedProducts', ContractExcludedProducts)
    .controller('ContractExcludedProductsDetail', ContractExcludedProductsDetail)
    .controller('ContractFeePricesCtrl', ContractFeePricesCtrl)
    .controller('ContractForAgreementCtrl', ContractForAgreementCtrl)
    .controller('ContractForAgreementDetailCtrl', ContractForAgreementDetailCtrl)
    .controller('ContractListCtrl', ContractListCtrl)
    .controller('ContractProductRatesCtrl', ContractProductRatesCtrl)
    .controller('ContractRelatedCtrl', ContractRelatedCtrl)
    .controller('ContractProductRatesDetailCtrl', ContractProductRatesDetailCtrl)
    .controller('ContractsDetailTabsCtrl', ContractsDetailTabsCtrl)
    .controller('ContractsFeesCtrl', ContractsFeesCtrl)
    .controller('CustomerFormCtrl', CustomerFormCtrl)
    .controller('DeferInvoicesCtrl', DeferInvoicesCtrl)
    .controller('DraftCreationCtrl', DraftCreationCtrl)
    .controller('DuplicateContractCtrl', DuplicateContractCtrl)
    .controller('InvoicesForContractCtrl', InvoicesForContractCtrl)
    .controller('ItemToBeInvoicedForContractCtrl', ItemToBeInvoicedForContractCtrl)
    .controller('ItemToBeInvoicedForContractDetailCtrl', ItemToBeInvoicedForContractDetailCtrl)
    .controller('PaymentDataFormCtrl', PaymentDataFormCtrl)
    .controller('SupplyFormCtrl', SupplyFormCtrl)
    .controller('ContractProductPriceCtrl', ContractProductPriceCtrl)
    .controller('ContractProductPriceDetailCtrl', ContractProductPriceDetailCtrl)
    .controller('ContractProductServiceCtrl', ContractProductServiceCtrl)
    .controller('ContractProductServicesDetailCtrl', ContractProductServicesDetailCtrl)
    .controller('AtrRequestListCtrl', AtrRequestListCtrl)
    .service('ContractAPI', ContractAPI)
    .service('ContractDetail', ContractDetail)
    .service('ContractForAgreement', ContractForAgreement)
    .service('CreateContract', CreateContract)
    .service('CreateDeferredInvoices', CreateDeferredInvoices)
    .service('ExcludedProductApi', ExcludedProductApi)
    .directive('contractsAgreement', contractsAgreement)
    .directive('contractCloseEstimated', contractCloseEstimated)
    .directive('adjustContract', adjustContract)
    .directive('generateFlatRateInvoices', generateFlatRateInvoices)
    .directive('contractCloseInvoice', contractCloseInvoice)
    .directive('contractListFilters', contractListFilters)
    .directive('contractDeletion', contractDeletion)
    .directive('contractExtraEmails', contractExtraEmails)
    .directive('contractInvoicingGenerationMode', contractInvoicingGenerationMode)
    .directive('contractListFiltersToInvoice', contractListFiltersToInvoice)
    .directive('contractsProductRate', contractsProductRate)
    .directive('contractsAddProductToBeInvoiced', contractsAddProductToBeInvoiced)
    .directive('contractsRate', contractsRate)
    .directive('contractStatus', contractStatus)
    .directive('contractsTypeInvoices', contractsTypeInvoices)
    .directive('contractUnsubscribe', contractUnsubscribe)
    .directive('contractBulkUnsubscribe', contractBulkUnsubscribe)
    .directive('contractWithdrawal', contractWithdrawal)
    .directive('renewProductRate', renewProductRate)
    .directive('contractsAddExcludedProduct', contractsAddExcludedProduct)

export {contracts}
