const ProviderInvoiceDetailCtrl = ['$scope', '$routeParams', '$filter', 'UserInfo', 'Alert', 'stringUtils', 'StatesMaster', 'CitiesMaster', 'ProviderInvoiceAPI', 'TaxRuleAPI', 'ProductAPI', 'Task',
    function ($scope, $routeParams, $filter, UserInfo, Alert,  stringUtils, StatesMaster, CitiesMaster, ProviderInvoiceAPI, TaxRuleAPI, ProductAPI, Task) {
        /***********************************************************************************************************
        * Controller Attributes
        ***********************************************************************************************************/
        var $translate = $filter('translate');

        $scope.PROVIDERS_INVOICE_EMITTER_TAB = require('../../../invoices/templates/invoice-emitter-detail.html');
        $scope.PROVIDERS_RECEIVER_DETAIL_TAB = require('../../templates/provider-invoice-receiver-detail.html');
        $scope.PROVIDERS_LOGS_TAB = require('../../templates/provider-invoices-detail-logs.html');

        /***********************************************************************************************************
        * Scope Attributes
        ***********************************************************************************************************/
        $scope.id = $routeParams.id;
        $scope.editionMode = false;
        $scope.invoice = {};
        $scope.loading = true;
        $scope.productFlag = false;
        $scope.contract_id= '';

        /***********************************************************************************************************
        * Scope Watchers
        ***********************************************************************************************************/
        // Asignacion de STATE a uno que exista en el master de STATES
        $scope.$watchGroup(['statesFlag', 'loadInvoice'], function (newValues, oldValues, scope) {
            if (newValues[0] && newValues[1]) {
                $scope.invoice.state = stringUtils.updateState($scope.states, $scope.invoice.state);
                $scope.invoice.company_state = stringUtils.updateState($scope.states, $scope.invoice.company_state);
            }
        });

        $scope.$watch('productFlag', function (newValue, oldValue, scope) {
            if (newValue) {
                $scope.loading = false;
            }
        });

        /***********************************************************************************************************
        * Controller Methods
        ***********************************************************************************************************/

        /***********************************************************************************************************
        * Scope Methods
        ***********************************************************************************************************/
        $scope.editMode = function () {
            $scope.editionMode = !$scope.editionMode;
        };

        $scope.resetItems = function() {
            if ($scope.selected_contract) {
                $scope.contract_id = $scope.selected_contract.id;
            } 
            let data = {'contract_id': $scope.contract_id}
            ProviderInvoiceAPI.resetItemsToBeInvoiced($scope.id, data).then(function(response){
                Alert.createAlert('success', response, true);
                $('#resetItemsModal').modal('toggle');
                $scope.contract_id = '';
            }, function(response){
                Alert.createAlert('danger', response, true);
                $('#resetItemsModal').modal('toggle');
            })
        };

        $scope.forceImport = function() {
            var task = new Task();

            task.type = 'FCPI';
            task.params = {'invoices': [$scope.id]}

            task.start().then(function (data, status, headers, config) {
                Alert.createAlert('success', $translate('PROVIDERS.FORCE_IMPORT_TASK'), true);
            }).catch(function (data, status, headers, config) {
                Alert.createAlert('danger', data.data, true);
            });
        };

        $scope.duplicateItems = function() {
            if ($scope.selected_contract) {
                $scope.contract_id = $scope.selected_contract.id;
            } 
            let data = {'contract_id': $scope.contract_id}
            ProviderInvoiceAPI.duplicateItemsToBeInvoiced($scope.id, data).then(function(response){
                Alert.createAlert('success', response, true);
                $scope.contract_id = '';
                $('#duplicateItemsModal').modal('toggle');
            }, function(response){
                Alert.createAlert('danger', response, true);
                $('#duplicateItemsModal').modal('toggle');
            })
        };

        $scope.editProviderInvoice = function () {
            var json = {
                'balance': $scope.invoice.balance,
                'start_date': $scope.invoice.start_date.toISOString().split('T')[0],
                'finish_date': $scope.invoice.finish_date.toISOString().split('T')[0],
                'observations': $scope.invoice.observations,
                'emission_date': $scope.invoice.emission_date,
                'receive_paper_invoice': $scope.invoice.receive_paper_invoice,
                'tax_base': $scope.invoice.tax_base,
                'tax_applied': $scope.invoice.tax_applied,
                'idn': $scope.invoice.idn,
                'total': $scope.invoice.total,
                'comments': $scope.invoice.comments,
                'creation_date': $scope.invoice.creation_date + 'T12:00'
            };

            if ($scope.invoice.paid_date !== null && $scope.invoice.paid_date !=='')
                json['paid_date'] = $scope.invoice.paid_date;

            if ($scope.invoice.expiration_date !== null && $scope.invoice.expiration_date !=='')
                json['expiration_date'] = $scope.invoice.expiration_date;


            ProviderInvoiceAPI.updateProviderInvoice($scope.id, json, true).then(function (provider_invoice) {
                $scope.editMode();
                Alert.createAlert('success', $translate('INVOICES.UPDATE_SUCCESS'), true);
            }).catch(function (error) {
                    Alert.createAlert('danger', $translate('INVOICES.UPDATE_ERROR'), true);
            });
        };

        $scope.canViewDetail = function () {
            return UserInfo.hasPerm('providers.retrieve_providerinvoices');
        };

        $scope.canEdit = function () {
            return UserInfo.hasPerm('providers.change_providerinvoice');
        };


        /***********************************************************************************************************
        * Controller Initialization
        ***********************************************************************************************************/
        StatesMaster.loadStates().then(function (response) {
            $scope.states = response;
            $scope.statesFlag = true;
        });

        CitiesMaster.loadCities().then(function(response){
            $scope.cities=response;
        });

        ProductAPI.getAllProducts(1000).then(function (data) {
            $scope.invoice_product = data.results;
            $scope.productFlag = true;
        }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
        });

        ProviderInvoiceAPI.getProviderInvoice($scope.id).then(function (resource) { 
            let invoice = resource.data

            $scope.invoice = invoice;
            $scope.reconciled = invoice.reconciled;
            $scope.invoice.start_date = new Date(invoice.start_date);
            $scope.invoice.finish_date = new Date(invoice.finish_date);
            $scope.invoice.emission_date = new Date(invoice.emission_date);
            $scope.invoice.creation_date = new Date(invoice.creation_date);
            $scope.invoice.paid_date = (invoice.paid_date) ?  new Date(invoice.paid_date) : null;
            $scope.invoice.expiration_date = (invoice.expiration_date) ?  new Date(invoice.expiration_date) : null;

            $scope.editable = !$scope.reconciled;
            $scope.loadInvoice = true;
            angular.forEach($scope.invoice.invoice_lines, function (value, key) {
                    if (value.start_date != null) {
                        value.start_date = new Date(value.start_date);
                    } else {
                        value.start_date = new Date(invoice.start_date);
                    }
                    if (value.finish_date != null) {
                        value.finish_date = new Date(value.finish_date);
                    } else {
                        value.finish_date = new Date(invoice.finish_date);
                    }
                });
        }).catch(function (error) {
                $scope.error_message = error.data.__all__[0];
        });


    }
]

export { ProviderInvoiceDetailCtrl }
