
const ContractListCtrl =  ['$scope', '$window', '$filter', 'Alert', 'Task', 'UserInfo', '$cookies', 'filtersUtils', 'INVOICES_COVERS', 'CitiesMaster', 'CONTRACT_STATUS', 'CONTRACT_SUB_STATUS',
        function ($scope, $window, $filter, Alert, Task, UserInfo, $cookies, filtersUtils, INVOICES_COVERS, CitiesMaster, CONTRACT_STATUS, CONTRACT_SUB_STATUS) {
            /***********************************************************************************************************
             * Controller Attributes
             ***********************************************************************************************************/
            var $translate = $filter('translate');
            var self = this;


            $scope.CONTRACT_ACTIONS_DROPDOWN = require('../../templates/actions-dropdown.html');
  
            /***********************************************************************************************************
             * Scope Attributes
             ***********************************************************************************************************/
            $scope.itemsToDownload = [];
            $scope.backgroundTasks = [];
            $scope.multipoint = {};

            $scope.ordering = {
                "creation_date": $translate('CONTRACTS.CREATION_DATE'),
                "id": $translate('CONTRACTS.CONTRACT_NUM'),
                "status": $translate('CONTRACTS.STATUS')
            };

            $scope.draggableFields = {
                "idn__in": {
                    "label": $translate('CONTRACTS.CONTRACT_NUM')
                },
                "cups__in": {
                    "label": $translate('CONTRACTS.SUPPLY_CUPS'),
                    "pattern": "^\\w+$"
                }
            };

            $scope.payment_methods = [
                {code: 'TRF', label: $translate('INVOICES.TRANSFER')},
                {code: 'DBT', label: $translate('INVOICES.DEBIT')},
                {code: 'B2B', label: $translate('INVOICES.DEBIT_B2B')},
                {code: 'GBT', label: $translate('INVOICES.DEBIT_GBT')}
            ];

            $scope.statuses = CONTRACT_STATUS;
            $scope.sub_statuses = CONTRACT_SUB_STATUS;

            $scope.invoice_covers = INVOICES_COVERS;

            $scope.user_info = UserInfo

            $scope.getStyles = function (result) {
                var styles = '';
                if (result.status == 'ACT')
                    styles += ' ' + 'success';
                if (result.status == 'PEN')
                    styles += ' ' + 'info';
                if (result.status == 'DRF' || result.status == 'SNT' || result.status == 'CHA' || result.status == 'APP' || result.status == 'SGN')
                    styles += ' ' + 'warning';
                if (result.status == 'INA' || result.status == 'INC' || result.status == 'NPR')
                    styles += ' ' + 'danger';
                return styles;
            };

            $scope.place = '';
            $scope.date = ''

            /***********************************************************************************************************
             * Scope Watchers
             ***********************************************************************************************************/
            $scope.$on("selectedCounterChange", function (e, selectedCounterChange) {
                $scope.counter = selectedCounterChange;
            });

            /***********************************************************************************************************
             * Controller Methods
             ***********************************************************************************************************/
            this.getJSONParams = function (elements) {
                var params = {};
                params.ids = filtersUtils.getItemsToDownloadToInt(elements);
                return params;
            };

            /***********************************************************************************************************
            * Scope Methods
            ***********************************************************************************************************/

            $scope.downloadExcel = function () {
                if ($scope.counter !== 0) {
                    var task = new Task();
                    task.type = 'CCSV';
                    task.params = self.getJSONParams($scope.itemsToDownload);
                    task.start().then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('CONTRACTS.BULK_CONTRACT_DOWNLOAD_CSV'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                    });
                }
            };

            $scope.downloadPdf = function (template, mode, supply_info) {
                if ($scope.counter === 1) {
                    var params = filtersUtils.getItemsToDownloadParams('ids', $scope.itemsToDownload);
                    var url = API_URL + '/contracts/download/pdf/' + params + "&template=" + template + "&place=" + $scope.place
                        + "&date=" + $scope.date + '&token=' + $cookies.get("token");
                    if (mode !== undefined) {
                        url += '&mode=' + mode;
                    }
                    if (supply_info !== undefined) {
                        url += '&supply_info=' + supply_info;
                    }
                    $('#downloadPdf').modal('toggle');
                    $scope.place = '';
                    $scope.date = '';
                    $window.open(url);
                    return false;
                } else if ($scope.counter > 1) {
                    var task = new Task();
                    task.type = 'CTDW';
                    task.params = self.getJSONParams($scope.itemsToDownload);
                    task.params.template = template;
                    if (mode !== undefined && mode !== '') {
                        task.params.mode = mode;
                    }
                    if (supply_info !== undefined && supply_info !== ''){
                        task.params.supply_info = supply_info;
                    }
                    task.params.place = $scope.place;
                    task.params.date = $scope.date;
                    task.start().then(function (data, status, headers, config) {
                        $('#downloadPdf').modal('toggle');
                        $scope.place = '';
                        $scope.date = '';
                        Alert.createAlert('success', $translate('CONTRACTS.Bulk download generation Task success'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    }, function (data, status, headers, config) {
                        $('#downloadPdf').modal('toggle');
                        Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                    });
                }
            };

            $scope.downloadSignedPdf = function () {
                if ($scope.counter > 0) {
                    var task = new Task();
                    task.type = 'CSIG';
                    task.params = self.getJSONParams($scope.itemsToDownload);

                    task.start().then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('CONTRACTS.Bulk download generation Task success'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('CONTRACTS.Bulk download generation Task error'), true);
                    });

                }
            };

            $scope.downloadMultipoint = function () {
                var params = $scope.multipoint;
                params.ids = filtersUtils.getItemsToDownloadToInt($scope.itemsToDownload);
                var task = new Task();
                task.type = 'MPCD';
                task.params = params;

                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.Download multipoint contract success'), true);
                    $scope.backgroundTasks.push(data.data.id);
                });

            };

            $scope.add_comment_contracts = function () {
                if ($scope.counter != 0) {
                    var task = new Task();
                    task.type = 'CACM';
                    $scope.update.ids = self.getJSONParams($scope.itemsToDownload).ids;
                    task.params = $scope.update;
                    task.start().then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('CONTRACTS.Bulk_Comment'), true);
                        $scope.backgroundTasks.push(data.data.id);
                        $scope.update.comment = '';
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('CONTRACTS.Bulk contract comments Task error'), true);
                        $scope.update_list.comment = '';
                    });

                }
            }

            $scope.duplicate_contracts_data = {
                'duplicate_services': true,
                'duplicate_special_prices': true
            }

            $scope.duplicate_contracts = function () {
                if ($scope.counter != 0) {
                    var task = new Task();
                    task.type = 'DCTR';
                    $scope.duplicate_contracts_data.ids = self.getJSONParams($scope.itemsToDownload).ids;
                    task.params = $scope.duplicate_contracts_data;

                    task.start().then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('CONTRACTS.Bulk_duplicate_contracts'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('CONTRACTS.Bulk duplicate contracts Task error'), true);
                    });
                }
            }

            $scope.force_contract_status = function () {
                if ($scope.counter != 0) {
                    var task = new Task();
                    task.type = 'FCST';
                    $scope.force_contracts_data.ids = self.getJSONParams($scope.itemsToDownload).ids;
                    task.params = $scope.force_contracts_data;

                    task.start().then(function (data, status, headers, config) {
                        Alert.createAlert('success', $translate('CONTRACTS.Bulk_force_contract_status'), true);
                        $scope.backgroundTasks.push(data.data.id);
                    }, function (data, status, headers, config) {
                        Alert.createAlert('danger', $translate('CONTRACTS.Bulk_force_contract_task_error'), true);
                    });
                }
            }

            $scope.canDownloadContractsXLS = function () {
                return UserInfo.hasPerm('contracts.download_contracts_excel');
            };
            $scope.canDownloadContractsPDF = function () {
                return UserInfo.hasPerm('contracts.download_contracts_pdf');
            };

            $scope.canSelectContractsForActions = function () {
                return this.can_change_status() ||
                    this.can_delete_contract() ||
                    this.can_change_rate() ||
                    this.can_change_agreement() ||
                    this.can_change_product_rate() ||
                    this.can_unsubscribe_contract();
            };

            $scope.can_change_status = function () {
                return UserInfo.hasPerm('contracts.draft_contract')
                    || UserInfo.hasPerm('contracts.sent_contract')
                    || UserInfo.hasPerm('contracts.application_contract')
                    || UserInfo.hasPerm('contracts.pending_contract')
                    || UserInfo.hasPerm('contracts.not_to_process_contract');
            };

            $scope.can_delete_contract = function () {
                return UserInfo.hasPerm('contracts.destroy_contracts');
            };

            $scope.can_change_rate = function () {
                return UserInfo.hasPerm('contracts.change_rate');
            };

            $scope.can_change_agreement = function () {
                return UserInfo.hasPerm('contracts.change_contract_agreement');
            };

            $scope.can_change_product_rate = function () {
                return UserInfo.hasPerm('contracts.change_product_rate');
            };

            $scope.can_update_contract = function () {
                return UserInfo.hasPerm('contracts.change_contract');
            };

            $scope.can_create_estimated_invoices = function ()  {
                return true;
            };

            $scope.can_unsubscribe_contract = function () {
                return UserInfo.hasPerm('contracts.can_unsubscibe_contract_request');
            };

            $scope.bulk_update = function () {
                var data = {};
                data['values'] = $scope.update;
                data['ids'] = self.getJSONParams($scope.itemsToDownload).ids;

                data['values']['expiration_date'] = $scope.expiration_date;

                if($scope.update.address == ', , '){
                    delete $scope.update.address
                }

                if(typeof $scope.update.extra_email_1 !== 'undefined' || typeof $scope.update.extra_email_2 !== 'undefined'  || typeof $scope.update.extra_email_3 !== 'undefined') {
                    $scope.update.extra_emails = '';

                    if ($scope.update.extra_email_1) {
                        $scope.update.extra_emails += $scope.update.extra_email_1 + ',';
                    } else {
                        $scope.update.extra_emails += ',';
                    }
                    delete $scope.update['extra_email_1'];

                    if ($scope.update.extra_email_2) {
                        $scope.update.extra_emails += $scope.update.extra_email_2 + ',';
                    } else {
                        $scope.update.extra_emails += ',';
                    }
                    delete $scope.update['extra_email_2'];

                    if ($scope.update.extra_email_3) {
                        $scope.update.extra_emails += $scope.update.extra_email_3;
                    }
                    delete $scope.update['extra_email_3'];

                    if ($scope.update.extra_emails == '') {
                        $scope.update['extra_emails'] = ',,';
                    }
                }

                var task = new Task();
                task.type = 'BUCD';
                task.params = data;
                task.start().then(function (data, status, headers, config) {
                    Alert.createAlert('success', $translate('CONTRACTS.BULK_UPDATE_QUEUED'), true);
                }, function (data, status, headers, config) {
                    Alert.createAlert('danger', $translate('CONTRACTS.BULK_UPDATE_REJECTED'), true);
                });

            };

            $scope.update_print_invoices = function () {
                if($scope.update.print_invoices == undefined){
                    $scope.update.print_invoices = false;
                }
                $scope.update.update_type = 'print_invoices';
                $scope.bulk_update();
            };

            $scope.update_invoice_cover = function () {
                $scope.update.update_type = 'invoice_cover';
                $scope.bulk_update();
            };

            $scope.update_postal_data = function () {
                $scope.update.update_type = 'postal_data';
                $scope.bulk_update();
            };

            $scope.update_payment_data = function () {
                $scope.update.update_type = 'payment_data';
                $scope.bulk_update();
            };

            $scope.update_observations = function () {
                $scope.update.update_type = 'observations_data';
                $scope.bulk_update();
            };

            $scope.update_remittance_day = function () {
                $scope.update.update_type = 'remittance_day';
                $scope.bulk_update();
            };

            $scope.update_expiration_date = function () {
                $scope.update.update_type = 'expiration_date_data';
                $scope.bulk_update();
            };

            CitiesMaster.loadCities().then(function(response){
                $scope.cities = response;
                $scope.citiesFlag = true;
            });

            $scope.onChangeExpirationDate = function(date){
                $scope.expiration_date = (date.lDate ? date.lDate : null).replace( new RegExp('/', 'g'), '-');
            }

        }
    ]

export {ContractListCtrl}
